import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, IconButton, Typography, alpha } from '@mui/material';
import { cyan, grey } from '@mui/material/colors';
import React from "react";
import { TSkipFlowControl } from "../resources/types";
import SkipFlowControl from "./SkipFlowControl";

const TaskUnitListItem = ({ isDragging = false, code, description, showAddIcon = false, onAdd, showDragHandle = false, skipFlowControl, onSkipFlowControlChange }: {
  isDragging?: boolean;
  code: string;
  description: string;
  showAddIcon?: boolean;
  showDragHandle?: boolean;
  skipFlowControl?: TSkipFlowControl;
  onSkipFlowControlChange?: (s: TSkipFlowControl) => void;
  onAdd?: () => void
}) => {

  React.useEffect(
    () => {
      console.debug(`TaskUnitListItem, skipFlowControl = ${JSON.stringify(skipFlowControl)}`)
    },
    [skipFlowControl]
  )

  const handleSkipFlowControlChange = (s: TSkipFlowControl) => {
    onSkipFlowControlChange && onSkipFlowControlChange(s)
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        userSelect: "none",
        padding: 1,
        marginTop: 0.5,
        marginBottom: 0.5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: cyan[500],
        borderRadius: 1,
        background: isDragging ? alpha(cyan[300], 0.6) : (showAddIcon ? grey[100] : cyan[200]),
        overflow: 'hidden'
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h6">{code}</Typography>
        <Typography variant="body2" sx={{ fontFamily: '"Roboto Condensed"' }}>{description}</Typography>
      </Box>
      {!onAdd && <Box display="flex" flexDirection="column">
        <SkipFlowControl skipFlowControl={skipFlowControl} onChange={handleSkipFlowControlChange}/>
      </Box>}
      {showAddIcon && <IconButton onClick={() => onAdd && onAdd()}><AddIcon sx={{ color: grey[700] }} /></IconButton>}
      {showDragHandle && <DragHandleIcon sx={{ color: grey[600] }} />}
    </Box>
  )
}

export default TaskUnitListItem