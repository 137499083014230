import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, Fab, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React from "react";
import 'react-dropzone-uploader/dist/styles.css';
import ProjectSelector from "../components/ProjectSelector";
import { SignedInUserContext } from '../contexts/SignedInUserContext';
import { canonize } from "../libraries/helper";
import { api } from '../resources/config';
import { TSignedInUserWithSetUser, TUser, TUserProject } from "../resources/types";
import { useSnackbar } from "../services/CustomSnackbarService";
import RoleSelector from "./RoleSelector";

const UserEditor = ({
  disabled = false,
  user,
  createNew = false,
  onChange,
  onSave,
  // onDelete
}: {
  disabled?: boolean;
  user: TUser;
  createNew?: boolean;
  onChange: (e: TUser) => void;
  onSave: ({
    username, note, file
  }: {
    username: string;
    note: string;
    file?: File
  }) => void;
  // onDelete: () => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const { username, name = '', password = '', role = '', note = '', projects = [], createdDate, modifiedDate } = user
  const usernameField = React.useRef<HTMLInputElement>(null)
  const fullnameField = React.useRef<HTMLInputElement>(null)
  const snackbar = useSnackbar();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (createNew) {
      onChange({
        ...user,
        username: canonize(event.target.value),
      })
    } else {
      console.warn(`handleUsernameChange(), should not allow to change user username`)
    }
  }

  const handleUsernameClear = () => {
    if (createNew) {
      onChange({
        ...user,
        username: '',
      })
    } else {
      console.warn(`handleUsernameClear(), should not allow to change user username`)
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...user,
      name: event.target.value
    })
  }

  const handleNameClear = () => {
    onChange({
      ...user,
      name: ''
    })
  }

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...user,
      note: event.target.value
    })
  }

  const handleNoteClear = () => {
    onChange({
      ...user,
      note: ''
    })
  }

  const handleProjectChange = (projects: TUserProject[]) => {
    onChange({
      ...user,
      projects
    })
  }

  const handleRoleChange = (role: string) => {
    onChange({
      ...user,
      role
    })
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('')
    onChange({
      ...user,
      password: event.target.value
    })
  }

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('')
    setConfirmPassword(event.target.value)
  }

  const detailsIncomplete = React.useMemo(
    () => !username,
    [username]
  )

  const handleSaveUserChanges = () => {
    if ((password || confirmPassword) && password !== confirmPassword) {
      setPasswordError('Password does not match')
      return snackbar({
        severity: 'error',
        message: `The passwords do not match`
      })
    }
    if (password && password.length < 8) {
      setPasswordError('Password must have at least 8 characters')
      return snackbar({
        severity: 'error',
        message: `Password must have at least 8 characters`
      })
    }
    if (createNew && !password) {
      return setPasswordError('Password must not be empty')
    }
    if (createNew && detailsIncomplete) {
      return snackbar({
        severity: 'error',
        message: `Please provide a user username`
      })
    }
    onSave({
      username,
      note,
      file: selectedFile ?? undefined
    })
  }

  // const handleDelete = () => {
  //   onDelete()
  // }

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  React.useEffect(
    () => {
      if (createNew) {
        setTimeout(
          () => usernameField.current?.focus(), 100
        )
      } else {
        setTimeout(
          () => fullnameField.current?.focus(), 100
        )
      }
    },
    [createNew]
  )

  const handleUsernameCheck = async () => {
    console.log(`handleUsernameCheck()`)
    if (!createNew || !username) {
      return
    }
    try {
      const response = await fetch(api.checkUserData({ username }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json())
      if (response.result) {
        console.log(`User "${username}" already exists`)
        setTimeout(
          () => usernameField.current?.focus(),
          100
        )
        snackbar({
          severity: 'error',
          message: `"${username}" already exists. Please use a different username and consider prepending with the project code.`
        })
      } else {
        console.log(`User "${username}" does not exist`)
        // snackbar({
        //   severity: 'info',
        //   message: `"${username}" is available`
        // })
      }
    } catch (err) {
      snackbar({
        severity: 'error',
        message: `Failed to check existence of "${username}": ${(err as Error).message}`
      })
    }
  }

  return (
    <Stack spacing={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          disabled={disabled}
          fullWidth
          label={'Username'}
          inputRef={usernameField}
          value={username}
          onChange={handleUsernameChange}
          onBlur={handleUsernameCheck}
          placeholder={'e.g. "peterchan"'}
          helperText={createNew ? 'Choose a unique name (without spaces) for this user. The name cannot be changed afterwards.' : 'Username becomes read-only once created.'}
          sx={{ flex: 1 }}
          InputProps={{
            readOnly: !createNew,
            endAdornment: createNew && [
              <InputAdornment position="end" key='clear'><IconButton onClick={handleUsernameClear}><ClearIcon /></IconButton></InputAdornment>,
              <Divider key="divider" orientation="vertical" variant="middle" flexItem />,
              <InputAdornment position="end" key='search'><IconButton color="primary" onClick={handleUsernameCheck}><SearchIcon /></IconButton></InputAdornment>
            ]
          }}
        />
        {!createNew && <Box display="flex" flexDirection="column" ml={2}>
          <Typography variant="caption" sx={{ fontSize: 'x-small' }}>{`Created: ${moment(createdDate).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
          <Typography variant="caption" sx={{ fontSize: 'x-small' }}>{`Updated: ${moment(modifiedDate).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
        </Box>}
      </Box>
      <TextField
        disabled={disabled}
        label={'Name'}
        inputRef={fullnameField}
        value={name}
        onChange={handleNameChange}
        placeholder={`e.g. "Peter Chan"`}
        helperText={createNew ? `Provide a name for this user` : 'Update the name of this user'}
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key='clear'><IconButton onClick={handleNameClear}><ClearIcon /></IconButton></InputAdornment>
          ]
        }}
      />
      <RoleSelector
        label={'Role'}
        managedRoles={signedInUserWithSetUser?.accessRight?.managedRoles ?? []}
        role={role}
        onChange={handleRoleChange}
        helperText={'Indicate the role of access level for this user'}
      />
      <TextField
        disabled={disabled}
        label={'Note'}
        value={note}
        onChange={handleNoteChange}
        placeholder={`e.g. "Admin user for project X"`}
        helperText={createNew ? `Input a brief note for this user` : 'Update the brief note for this user'}
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key='clear'><IconButton onClick={handleNoteClear}><ClearIcon /></IconButton></InputAdornment>
          ]
        }}
      />
      <ProjectSelector
        selectedProjects={projects}
        onChange={handleProjectChange}
      />
      <TextField
        disabled={disabled}
        type="password"
        label={'Password'}
        value={password}
        autoComplete='off'
        onChange={handlePasswordChange}
        placeholder={`At least 8 characters or digits`}
        helperText={passwordError ? passwordError : createNew ? `Enter a password` : 'Enter a new password if needed. Leave it blank to keep it unchanged.'}
        error={!!passwordError}
      />
      <TextField
        disabled={disabled}
        type="password"
        label={'Verify password'}
        value={confirmPassword}
        autoComplete='off'
        onChange={handleConfirmPasswordChange}
        placeholder={`Enter the same password again`}
        helperText={passwordError ? passwordError : `Enter the same password for verification`}
        error={!!passwordError}
      />
      <Fab
        color="primary"
        aria-label="save"
        variant="extended"
        onClick={handleSaveUserChanges}
        sx={{ position: "fixed", bottom: 32, right: 32 }}
      >
        <SaveIcon sx={{ mr: 1 }} />
        Save
      </Fab>
      {/*<SpeedDial
        ariaLabel={"Save Changes"}
        icon={<SpeedDialIcon icon={<SettingsIcon />} openIcon={<SettingsTwoToneIcon />} />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        direction={"up"}
        hidden={disabled}
        sx={{
          position: "fixed",
          "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: 32,
            right: 32,
          },
          "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            top: 32,
            left: 32,
          },
        }}
      >
        <SpeedDialAction
          icon={<SaveIcon />}
          tooltipTitle={"Save Changes"}
          tooltipOpen
          onClick={handleSaveUserChanges}
        />
        {!createNew && <SpeedDialAction
          icon={<DeleteIcon />}
          tooltipTitle={"Delete"}
          tooltipOpen
          onClick={handleDelete}
        />}
        </SpeedDial>*/}
    </Stack>
  )
}

export default UserEditor