import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography
} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CrllsLogo from "../assets/images/crlls-logo.png";
import ResponsiveAppBar from "../components/AppBar";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";

function ContactUsPage() {
  return (
    <Paper elevation={0}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "contactus"]} />
      <Box sx={{ mt: 4, ml: 4, mr: "25%", minWidth: 600, pb: 16 }}>
        <Typography variant="h3">Contact Us</Typography>
        <Box sx={{ m: 4 }}>
          <Link href="https://crlls.eduhk.hk/crlls/">
            <img
              src={CrllsLogo}
              alt="CRLLS"
              height={160}
              style={{ maxWidth: "60%" }}
            />
          </Link>
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Centre for Research on Linguistics and Language Studies
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            Phone: +852 2948 7753
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            Email: crlls@eduhk.hk
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
}

export default ContactUsPage;
