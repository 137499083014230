import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { blue, lightBlue } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import {
  canonize,
  extractMedia,
  getStimulusType,
  renderAsHTML,
} from "../libraries/helper";
import { api, mediaPath } from "../resources/config";
import jsPsychPluginPrototypes from "../resources/jsPsychPluginPrototypes";
import { TMedia, TSignedInUserWithSetUser, TTaskUnit } from "../resources/types";
import { useSnackbar } from "../services/CustomSnackbarService";
import LanguageSelector from "./LanguageSelector";
import TaskUnitJsPsychPluginConfigurator from "./TaskUnitJsPsychPluginConfigurator";
import TaskUnitJsPsychPluginSelector from "./TaskUnitJsPsychPluginSelector";

const TaskUnitEditor = ({
  disabled = false,
  taskUnit,
  createNew = false,
  onChange,
}: {
  disabled?: boolean;
  taskUnit: TTaskUnit;
  createNew?: boolean;
  onChange: (e: TTaskUnit) => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [selectedPlugInType, setSelectedPlugInType] = React.useState("");
  const { code, language, description, details, createdDate, modifiedDate } =
    taskUnit;
  const codeInputRef = React.useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();

  const plugInTypes = React.useMemo(
    () =>
      jsPsychPluginPrototypes.map((v) => ({
        id: v.type,
        text: v.description,
        responseType: v.responseType,
      })),
    []
  );

  React.useEffect(() => {
    if (taskUnit?.details?.type) {
      setSelectedPlugInType(taskUnit?.details?.type);
    }
  }, [taskUnit]);

  const handleTaskUnitIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (createNew) {
      onChange({
        ...taskUnit,
        code: canonize(event.target.value),
      });
    } else {
      console.warn(
        `handleTaskUnitIdChange(), should not allow to change task unit ID`
      );
    }
  };

  const handleTaskUnitIdClear = () => {
    if (createNew) {
      onChange({
        ...taskUnit,
        code: "",
      });
    } else {
      console.warn(
        `handleTaskUnitIdClear(), should not allow to change task unit ID`
      );
    }
  };

  const handleTaskUnitIdCheck = async () => {
    console.log(`handleTaskUnitIdCheck()`);
    if (!createNew || !code) {
      return;
    }
    try {
      const response = await fetch(api.checkTaskUnitData({ code }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => res.json());
      if (response.result) {
        console.log(`task unit "${code}" already exists`);
        setTimeout(() => codeInputRef.current?.focus(), 100);
        snackbar({
          severity: "error",
          message: `"${code}" already exists. Please use a different ID and consider prepending with the project code.`,
        });
      } else {
        console.log(`task unit "${code}" does not exist`);
        // snackbar({
        //   severity: 'info',
        //   message: `"${code}" can be used as task unit ID`
        // })
      }
    } catch (err) {
      snackbar({
        severity: "error",
        message: `Failed to check existence of "${code}": ${
          (err as Error).message
        }`,
      });
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...taskUnit,
      description: event.target.value,
    });
  };

  const handleDescriptionClear = () => {
    onChange({
      ...taskUnit,
      description: "",
    });
  };

  const handleLanguageChange = (language: string) => {
    onChange({
      ...taskUnit,
      language,
    });
  };

  const handlePlugInTypeChange = (plugInType: string) => {
    setSelectedPlugInType(plugInType);
    const targetPluginPrototype = jsPsychPluginPrototypes.find(
      (v) => v.type === plugInType
    );
    const newTaskUnit: any = {
      ...taskUnit,
      details: {
        // ...taskUnit.details,
        type: plugInType,
      },
    };
    // onChange({
    //   ...taskUnit,
    //   details: {
    //     ...taskUnit.details,
    //     type: plugInType
    //   }
    // })
    Object.keys(targetPluginPrototype?.attributes ?? {}).forEach((v) => {
      if (taskUnit.details[v]) {
        if (
          typeof taskUnit.details[v] ===
          targetPluginPrototype?.attributes?.[v]?.type
        ) {
          newTaskUnit.details[v] = taskUnit.details[v];
        } else if (
          targetPluginPrototype?.attributes?.[v]?.type === "string[]" &&
          taskUnit.details[v] instanceof Array
        ) {
          newTaskUnit.details[v] = taskUnit.details[v].map((v: any) =>
            v.toString()
          );
        } else if (
          targetPluginPrototype?.attributes?.[v]?.type === "string" &&
          taskUnit.details[v] instanceof Array
        ) {
          newTaskUnit.details[v] = taskUnit.details[v].join(",");
        }
      }
      if (
        typeof targetPluginPrototype?.attributes?.[v]?.defaultValue !==
          "undefined" &&
        typeof taskUnit.details[v] === "undefined"
      ) {
        console.log(
          `handlePlugInTypeChange(), set ${v} as ${targetPluginPrototype?.attributes?.[v]?.defaultValue}`
        );
        newTaskUnit.details[v] =
          targetPluginPrototype?.attributes?.[v]?.defaultValue;
      } else if (
        targetPluginPrototype?.attributes?.[v]?.defaultValue &&
        taskUnit.details[v]
      ) {
        console.log(
          `handlePlugInTypeChange(), there's value. no need to set default for ${v}`
        );
      }
    });
    onChange(newTaskUnit as TTaskUnit);
  };

  const handleJsPsychPluginChange = (attribute: string, value: any) => {
    const targetPluginPrototype = jsPsychPluginPrototypes.find(
      (v) => v.type === selectedPlugInType
    );
    const attributePrototype = targetPluginPrototype?.attributes?.[attribute];
    const { mediaWidth, mediaHeight, mediaPrompt } = taskUnit.details;
    if (
      ["media", "media[]"].includes(attributePrototype.type) &&
      value === "last_response"
    ) {
      onChange({
        ...taskUnit,
        details: {
          ...taskUnit.details,
          [attribute]: value,
          ...(attributePrototype.mediaKey && {
            [attributePrototype.mediaKey]: undefined,
          }),
        },
      });
      return;
    }
    const { code, mimeTypeMajor, filename, url } = (value as TMedia) ?? {};
    console.log(`handleJsPsychPluginChange: attribute: ${attribute}`);
    console.log(`handleJsPsychPluginChange: value: ${JSON.stringify(value)}`);
    console.log(
      `handleJsPsychPluginChange: attributePrototype, ${JSON.stringify(
        attributePrototype
      )}`
    );
    if (
      ["media", "media[]"].includes(attributePrototype.type) &&
      attributePrototype.mediaKey
    ) {
      const path = url ? url : filename ? `${mediaPath}/${filename}` : "";
      if (attributePrototype.renderAsHTML) {
        console.log("render as HTML!");
        const rendered = renderAsHTML({
          type: mimeTypeMajor,
          url: path,
          ...(mediaWidth || mediaHeight || mediaPrompt
            ? {
                options: {
                  mediaWidth,
                  mediaHeight,
                  mediaPrompt,
                },
              }
            : {}),
        });
        onChange({
          ...taskUnit,
          details: {
            ...taskUnit.details,
            [attribute]: rendered
              ? attributePrototype.type === "media"
                ? rendered
                : [rendered]
              : undefined,
            [attributePrototype.mediaKey]: code,
          },
        });
      } else {
        onChange({
          ...taskUnit,
          details: {
            ...taskUnit.details,
            [attribute]: path
              ? attributePrototype.type === "media"
                ? path
                : [path]
              : undefined,
            [attributePrototype.mediaKey]: code,
          },
        });
      }
    } else if (attributePrototype.reRenderStimulus) {
      const { stimulusKey } = attributePrototype;
      const stimulus = taskUnit.details?.[stimulusKey];
      if (stimulus !== "last_response") {
        const mediaType = getStimulusType(stimulus);
        const allMedia = extractMedia(stimulus);
        console.log(
          `Need to rerender stimulus. stimulus=${stimulus}, mediaType=${mediaType}`
        );

        if (mediaType) {
          const path =
            mediaType === "image"
              ? allMedia.images[0]
              : mediaType === "video"
              ? allMedia.videos[0]
              : allMedia.audio[0];
          console.log(`Need to rerender stimulus. path = ${path}`);
          const rendered = renderAsHTML({
            type: mediaType,
            url: path,
            options: {
              mediaWidth,
              mediaHeight,
              mediaPrompt,
              [attribute]: value,
            },
          });
          console.log(`Need to rerender stimulus. rendered = ${rendered}`);

          onChange({
            ...taskUnit,
            details: {
              ...taskUnit.details,
              [attribute]: value,
              [stimulusKey]: rendered,
            },
          });
        }
      } else {
        onChange({
          ...taskUnit,
          details: {
            ...taskUnit.details,
            [attribute]: value
          }
        })
      }
      // } else if (attributePrototype.supportedMediaTypes) {
      //   const { stimulusKey, property } = attributePrototype;
      //   const mediaKey =
      //     stimulusKey &&
      //     targetPluginPrototype?.attributes?.[stimulusKey]?.mediaKey;
      //   const stimulusCode = taskUnit.details?.[mediaKey];

      //   console.log(
      //     `handleJsPsychPluginChange(), need to re-render stimulus, stimulusKey=${stimulusKey}, mediaKey=${mediaKey}, stimulusCode=${stimulusCode}`
      //   );

      //   const stimulus = taskUnit.details?.[stimulusKey];
      //   const mediaType = getStimulusType(stimulus);
      //   console.log(`mediaType = ${mediaType}`);
      //   if (attributePrototype.supportedMediaTypes.includes(mediaType)) {
      //     const regex = new RegExp(`\\b${property}="\\d+"`, "i");
      //     const replacement = `${property}="${value}"`;
      //     console.log(`regex=${regex}, replacement=${replacement}`);

      //     onChange({
      //       ...taskUnit,
      //       details: {
      //         ...taskUnit.details,
      //         [attribute]: value,
      //         [stimulusKey]: stimulus.replace(regex, replacement),
      //       },
      //     });
      //   } else {
      //     onChange({
      //       ...taskUnit,
      //       details: {
      //         ...taskUnit.details,
      //         [attribute]: value,
      //       },
      //     });
      //   }
    } else {
      onChange({
        ...taskUnit,
        details: {
          ...taskUnit.details,
          [attribute]: value,
        },
      });
    }
  };

  return (
    <Stack spacing={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          disabled={disabled}
          fullWidth
          inputRef={codeInputRef}
          label={"Task Unit ID"}
          value={code}
          onChange={handleTaskUnitIdChange}
          onBlur={handleTaskUnitIdCheck}
          placeholder={"e.g. ctnCompC2"}
          helperText={
            createNew
              ? "Choose a unique name (without spaces) for this task unit. The name cannot be changed afterwards."
              : "Task Unit ID is read-only once created."
          }
          sx={{ flex: 1 }}
          InputProps={{
            readOnly: !createNew,
            endAdornment: createNew && [
              <InputAdornment position="end" key="clear">
                <IconButton onClick={handleTaskUnitIdClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>,
              <Divider
                key="divider"
                orientation="vertical"
                variant="middle"
                flexItem
              />,
              <InputAdornment position="end" key="search">
                <IconButton color="primary" onClick={handleTaskUnitIdCheck}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>,
            ],
          }}
        />
        {!createNew && (
          <Box display="flex" flexDirection="column" ml={2}>
            <Typography
              variant="caption"
              sx={{ fontSize: "x-small" }}
            >{`Created: ${moment(createdDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: "x-small" }}
            >{`Updated: ${moment(modifiedDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</Typography>
          </Box>
        )}
      </Box>
      <TextField
        disabled={disabled}
        label={"Description"}
        value={description}
        onChange={handleDescriptionChange}
        placeholder={`e.g. "Cantonese cat story comprehension #2"`}
        helperText={
          createNew
            ? `Input a brief description to describe this task unit`
            : "Update the description of this task unit"
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleDescriptionClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <LanguageSelector
        disabled={disabled}
        label={"Language"}
        language={language}
        allowNil={true}
        onChange={handleLanguageChange}
        helperText={
          "Indicate the language of this task unit for auto-transcription"
        }
      />
      <TaskUnitJsPsychPluginSelector
        disabled={disabled}
        label={"Plugin type"}
        plugInTypes={plugInTypes}
        selectedPlugInType={selectedPlugInType}
        onChange={handlePlugInTypeChange}
      />
      {!!selectedPlugInType && (
        <Box
          sx={{
            padding: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 2,
            borderColor: blue[300],
            backgroundColor: lightBlue[50],
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Plugin configuration
          </Typography>
          <TaskUnitJsPsychPluginConfigurator
            disabled={disabled}
            plugInPrototype={jsPsychPluginPrototypes.find(
              (v) => v.type === selectedPlugInType
            )}
            plugInConfig={details}
            onChange={handleJsPsychPluginChange}
          />
        </Box>
      )}
    </Stack>
  );
};

export default TaskUnitEditor;
