import { MenuItem, TextField, SxProps } from "@mui/material"

const PaginationControl = ({ pageSize, onPageSizeChange, sx }: {
    pageSize: number;
    onPageSizeChange: (newPageSize: number) => void;
    sx?: SxProps;
}) => {
    return (<TextField
        select
        label="Rows per page"
        value={pageSize}
        onChange={(event) => onPageSizeChange(Number(event.target.value))}
        variant="standard"
        size="small"
        sx={{ width: 120, mr: 4, ...sx }}
    >
        {[5, 10, 25, 50].map((size) => (
            <MenuItem key={size} value={size}>
                {size}
            </MenuItem>
        ))}
    </TextField>)
}

export default PaginationControl