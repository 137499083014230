import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { TSkipFlowControl } from "../resources/types";
import EqualityCheckSelector from "./EqualityCheckSelector";
import SkipFlowControlDescription from "./SkipFlowControlDescription";

const SkipFlowControlDialog = ({
  show,
  enabled,
  lastNthElement,
  equalityCheck,
  targetValue,
  onConfirm,
  onClose,
  onCancel,
}: {
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: (s: TSkipFlowControl) => void;
} & TSkipFlowControl) => {
  const [localEnabled, setLocalEnabled] = React.useState<boolean>(true);
  const [localLastNthElement, setLocalLastNthElement] =
    React.useState<number>(1);
  const [localEqualityCheck, setLocalEqualityCheck] =
    React.useState<boolean>(true);
  const [localTargetValue, setLocalTargetValue] = React.useState<string>("0");
  const [expandedNotes, setExpandedNotes] = React.useState(false);

  React.useEffect(() => {
    setLocalEnabled(enabled ?? true);
    setLocalLastNthElement(lastNthElement ?? 1);
    setLocalEqualityCheck(equalityCheck ?? true);
    setLocalTargetValue(targetValue ?? "0");
  }, [enabled, lastNthElement, equalityCheck, targetValue]);

  const handleChangeLocalLastNthElement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalLastNthElement(Number(event.target.value));
  };

  const handleChangeLocalEqualityCheck = (value: boolean) => {
    setLocalEqualityCheck(value);
  };

  const handleChangeLocalTargetValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalTargetValue(event.target.value);
  };

  const handleConfirm = () => {
    if (localEnabled) {
      onConfirm({
        enabled: localEnabled,
        lastNthElement: localLastNthElement,
        equalityCheck: localEqualityCheck,
        targetValue: localTargetValue,
      });
    } else {
      onConfirm({
        enabled: false,
      });
    }
  };

  const handleChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalEnabled(event.target.checked);
    if (!event.target.checked) {
      setLocalLastNthElement(1);
      setLocalEqualityCheck(true);
      setLocalTargetValue("0");
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={show}>
      <DialogTitle>Define criterion to skip task unit</DialogTitle>
      <DialogContent>
        <Stack>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Switch checked={localEnabled} onChange={handleChangeEnabled} />
            <Typography>
              {localEnabled ? "Skip is enabled" : "Skip not enabled"}
            </Typography>
          </Stack>
          {localEnabled && (
            <Grid
              container
              sx={{ pt: 2 }}
              spacing={4}
              justifyContent={"space-evenly"}
            >
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  disabled={!localEnabled}
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  label={
                    <Typography>
                      Previous{" "}
                      <Typography
                        sx={{
                          color: blue[900],
                          display: "inline",
                          fontWeight: "bold",
                          fontStyle: "italic",
                          marginX: 0.2,
                        }}
                      >
                        n
                      </Typography>
                      <Typography
                        style={{
                          display: "inline",
                          verticalAlign: "super",
                          fontSize: "70%",
                          fontStyle: "italic",
                          fontFamily: "serif",
                        }}
                      >
                        th
                      </Typography>{" "}
                      item to check
                    </Typography>
                  }
                  value={localLastNthElement}
                  onChange={handleChangeLocalLastNthElement}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <EqualityCheckSelector
                  label={"Logic check"}
                  disabled={!localEnabled}
                  selectedEqualityCheck={localEqualityCheck}
                  onChange={handleChangeLocalEqualityCheck}
                />
              </Grid>
              <Grid item xs={6} md={5}>
                <TextField
                  disabled={!localEnabled}
                  fullWidth
                  variant="outlined"
                  label="Target value"
                  value={localTargetValue}
                  onChange={handleChangeLocalTargetValue}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={
                          expandedNotes
                            ? "Click to close notes"
                            : "Click to see notes"
                        }
                      >
                        <IconButton
                          color="primary"
                          onClick={() => setExpandedNotes(!expandedNotes)}
                        >
                          {expandedNotes ? <HelpIcon /> : <HelpOutlineIcon />}
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}
          {localEnabled && expandedNotes && (
            <Fade>
              <Grid container flexDirection={"column"} alignItems={"flex-end"}>
                <Grid item xs={12} md={9}>
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderColor: grey[300],
                      borderWidth: 1,
                      borderRadius: 1,
                      borderStyle: "solid",
                    }}
                  >
                    <Stack
                      flexDirection={"row"}
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                      sx={{ width: "100%" }}
                    >
                      <Typography variant="h6">Note on target value</Typography>
                      <IconButton onClick={() => setExpandedNotes(false)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Typography variant="body1">
                      If the previous task consists of a list of buttons, the
                      response value will be a number starting from "0", e.g.
                      "0", "1", ...
                    </Typography>
                    <Typography variant="body1">
                      If it accepts keyboard response, the value will be the
                      character itself, like "a", "1", "?", or the name of the
                      key in lowercase, like "arrowup", "enter", ...
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Fade>
          )}
          {localEnabled && (
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <SkipFlowControlDescription
                    prefix="The task unit will be skipped if the value of the"
                    lastNthElement={localLastNthElement}
                    equalityCheck={localEqualityCheck}
                    targetValue={localTargetValue}
                  />
                ),
              }}
              sx={{
                mt: 2,
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<CancelIcon />} onClick={onCancel} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button startIcon={<CheckCircleOutlineIcon />} onClick={handleConfirm}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkipFlowControlDialog;
