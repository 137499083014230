import ClearIcon from "@mui/icons-material/Clear";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import SearchIcon from "@mui/icons-material/Search";
import UpdateIcon from "@mui/icons-material/Update";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { canonize } from "../libraries/helper";
import { api } from "../resources/config";
import { TExperiment, TSignedInUserWithSetUser, TSkipFlowControl } from "../resources/types";
import { useSnackbar } from "../services/CustomSnackbarService";
import ExperimentLanguageSelector from "./ExperimentLanguageSelector";
import TaskUnitArranger from "./TaskUnitArranger";

const ExperimentEditor = ({
  disabled = false,
  experiment,
  createNew = false,
  onChange,
}: {
  disabled?: boolean;
  experiment: TExperiment;
  createNew?: boolean;
  onChange: (e: TExperiment) => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const {
    code,
    language,
    description,
    testMic,
    allowRestart,
    taskUnits,
    skipFlowControls,
    createdDate,
    modifiedDate,
  } = experiment;
  const codeInputRef = React.useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();

  const taskUnitCodes = React.useMemo(() => {
    return taskUnits.map((p) => (typeof p === "string" ? p : p.code));
  }, [taskUnits]);

  const handleExperimentIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (createNew) {
      onChange({
        ...experiment,
        code: canonize(event.target.value),
      });
    } else {
      console.warn(
        `handleExperimentIdChange(), should not allow to change experiment ID`
      );
    }
  };

  const handleExperimentIdClear = () => {
    if (createNew) {
      onChange({
        ...experiment,
        code: "",
      });
    } else {
      console.warn(
        `handleExperimentIdClear(), should not allow to change experiment ID`
      );
    }
  };

  const handleExperimentIdCheck = async () => {
    console.debug(`handleExperimentIdCheck()`);
    if (!createNew || !code) {
      return;
    }
    try {
      const response = await fetch(api.checkExperimentData({ code }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => res.json());
      if (response.result) {
        console.log(`experiment "${code}" already exists`);
        setTimeout(() => codeInputRef.current?.focus(), 100);
        snackbar({
          severity: "error",
          message: `"${code}" already exists. Please use a different ID and consider prepending with the project code.`,
        });
      } else {
        console.log(`experiment "${code}" does not exist`);
        // snackbar({
        //   severity: 'info',
        //   message: `"${code}" can be used as experiment ID`
        // })
      }
    } catch (err) {
      snackbar({
        severity: "error",
        message: `Failed to check existence of "${code}": ${
          (err as Error).message
        }`,
      });
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...experiment,
      description: event.target.value,
    });
  };

  const handleDescriptionClear = () => {
    onChange({
      ...experiment,
      description: "",
    });
  };

  const handleTestMicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...experiment,
      testMic: event.target.checked,
    });
  };

  const handleTestMicToggle = () => {
    onChange({
      ...experiment,
      testMic: !experiment.testMic,
    });
  };

  const handleAllowRestartToggle = () => {
    onChange({
      ...experiment,
      allowRestart: !experiment.allowRestart,
    });
  };

  const handleLanguageChange = (language: string) => {
    onChange({
      ...experiment,
      language,
    });
  };

  const handleTaskUnitChange = (
    taskUnits: string[],
    skipFlowControls: TSkipFlowControl[]
  ) => {
    console.log(
      `handleTaskUnitChange(), taskUnits = ${JSON.stringify(
        taskUnits
      )}, skipFlowControls = ${JSON.stringify(skipFlowControls)}`
    );
    onChange({
      ...experiment,
      taskUnits,
      skipFlowControls,
    });
  };

  return (
    <Stack spacing={2} sx={{ mb: 10 }}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          disabled={disabled}
          inputRef={codeInputRef}
          fullWidth
          label={"Experiment ID"}
          value={code}
          onChange={handleExperimentIdChange}
          onBlur={handleExperimentIdCheck}
          sx={{ flex: 1 }}
          helperText={
            createNew
              ? "Choose a unique name (in alphanumeric and dash only) for this experiment. The name cannot be changed afterwards."
              : "Experiment ID is read-only once created."
          }
          InputProps={{
            readOnly:
              !createNew ||
              !signedInUserWithSetUser?.accessRight?.canEditExperiments,
            endAdornment: createNew && [
              <InputAdornment position="end" key="clear">
                <IconButton onClick={handleExperimentIdClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>,
              <Divider
                key="divider"
                orientation="vertical"
                variant="middle"
                flexItem
              />,
              <InputAdornment position="end" key="search">
                <IconButton color="primary" onClick={handleExperimentIdCheck}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>,
            ],
          }}
        />
        {!createNew && (
          <Box display="flex" flexDirection="column" ml={2}>
            <Typography
              variant="caption"
              sx={{ fontSize: "x-small" }}
            >{`Created: ${moment(createdDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: "x-small" }}
            >{`Updated: ${moment(modifiedDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</Typography>
          </Box>
        )}
      </Box>
      <TextField
        label={"Description"}
        value={description}
        onChange={handleDescriptionChange}
        helperText={
          createNew
            ? `Input a brief description to describe this experiment`
            : "Update the description of this experiment"
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleDescriptionClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <TextField
        fullWidth
        label={"Requires microphone testing"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Switch checked={!!testMic} onChange={handleTestMicChange} />
              {!!testMic ? (
                <IconButton onClick={handleTestMicToggle}>
                  <MicIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleTestMicToggle}>
                  <MicOffIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          readOnly: true,
        }}
        sx={{
          mt: 2,
          mb: 2,
        }}
        helperText={"Enable microphone testing if voice recording is required"}
      />
      <TextField
        fullWidth
        label={"Allows restarting experiment"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Switch
                checked={!!allowRestart}
                onChange={handleAllowRestartToggle}
              />
              {!!allowRestart ? (
                <IconButton onClick={handleAllowRestartToggle}>
                  <UpdateIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleAllowRestartToggle}>
                  <UpdateDisabledIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          readOnly: true,
        }}
        sx={{
          mt: 2,
          mb: 2,
        }}
        helperText={
          "Enable this if participant is allowed to restart the experiment"
        }
      />
      <ExperimentLanguageSelector
        label={"Language"}
        language={language}
        required={true}
        onChange={handleLanguageChange}
        helperText={
          "This affects the language of experiment notes and instructions"
        }
      />
      {/*<TaskUnitSelector taskUnits={taskUnitCodes} onChange={handleTaskUnitChange} />*/}
      <TaskUnitArranger
        taskUnits={taskUnitCodes}
        skipFlowControls={skipFlowControls}
        onChange={handleTaskUnitChange}
      />
    </Stack>
  );
};

export default ExperimentEditor;
