import { Autocomplete, Box, TextField } from '@mui/material';
import React from "react";
import { useQuery } from 'react-query';
import { SignedInUserContext } from '../contexts/SignedInUserContext';
import { api } from "../resources/config";
import { TProject, TSignedInUserWithSetUser, TUserProject } from "../resources/types";

const ProjectSelector = ({
  selectedProjects,
  onChange,
  helperText = ''
}: {
  selectedProjects: TUserProject[];
  onChange?: (projects: TUserProject[]) => void;
  helperText?: string;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const [projectData, setProjectData] = React.useState<TUserProject[]>([]);
  const [open, setOpen] = React.useState(false)

  const { isLoading, isFetching, error, data: rawProjectData, refetch: refetchProjectData } = useQuery(
    ['project-data'],
    () =>
      fetch(api.getProjects({}), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json()),
    {
      enabled: !!signedInUserWithSetUser?.accessRight?.canManageAllProjects,
    }
  );

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event: React.ChangeEvent<{}>, value: TUserProject[]) => {
    console.log(value)
    onChange && onChange(value.map(v => ({ code: v.code })))
  }

  React.useEffect(
    () => {
      if (rawProjectData && rawProjectData.docs instanceof Array) {
        setProjectData([
          ...(rawProjectData.docs as TProject[])
        ])
      }
    },
    [rawProjectData]
  )

  React.useEffect(
    () => {
      console.log(`set up project data`)
      if (signedInUserWithSetUser?.accessRight?.canManageAllProjects && rawProjectData && rawProjectData.docs instanceof Array) {
        console.log(`all projects`)
        setProjectData([
          ...(rawProjectData.docs as TProject[])
        ])
      } else {
        console.log(`designated projects only`)
        setProjectData(signedInUserWithSetUser?.projects ?? [])
      }
    },
    [signedInUserWithSetUser?.projects, rawProjectData, signedInUserWithSetUser?.accessRight?.canManageAllProjects]
  )

  return (
    <Box>
      {projectData && <Autocomplete
        multiple
        options={projectData}
        getOptionLabel={(option: TUserProject) => `${option?.code}: ${option?.name}`}
        value={selectedProjects.map(p => projectData.find((v: TUserProject) => v.code === p.code)) as TUserProject[]}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Projects"
            autoComplete='off'
            placeholder="Click or type to search"
            helperText={helperText || 'Choose the projects this user belongs to'}
          />
        )}
        onChange={handleChange}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      />}
    </Box>
  )
};

export default ProjectSelector
