import React from "react";
import { Routes, HashRouter, Route, Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import LandingPage from "./pages/LandingPage";
// import AdminPage from "./pages/AdminPage";
import Dashboard from "./pages/Dashboard";
// import SurveyPage from "./pages/Survey";
import TaskPage from "./pages/TaskPage";
// import SurveyDataPage from "./pages/SurveyDataPage";
import AudioDataPage from "./pages/AudioDataPage";
import TextDataPage from "./pages/TextDataPage";
import ExperimentPage from "./pages/ExperimentPage";
import TaskUnitPage from "./pages/TaskUnitPage";
import MediaPage from "./pages/MediaPage";
import MainMenuPage from "./pages/MainMenuPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import DocumentationPage from "./pages/DocumentationPage";
import LearnMorePage from "./pages/LearnMorePage";
import ProjectPage from "./pages/ProjectPage";
import UserPage from "./pages/UserPage";
import SignUpPage from "./pages/SignUpPage";
import { ParticipantContext } from "./contexts/ParticipantContext";
import { SignedInUserContext } from "./contexts/SignedInUserContext";
import {
  Participant,
  TSignedInUser,
  TUserProject,
  TAccessRights,
} from "./resources/types";
import { persistenceKey, api, accessRights } from "./resources/config";
import "./App.css";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import LoginPage from "./pages/LoginPage";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const Integration = () => {
  const [participant, setParticipant] = React.useState<Participant | null>(
    null
  );
  const [signedInUser, setSignedInUser] = React.useState<TSignedInUser | null>(
    null
  );
  const [projectCodeInUse, setProjectCodeInUse] = React.useState<string>("");
  const [projects, setProjects] = React.useState<TUserProject[]>([]);
  const [pageSize, setPageSize] = React.useState(50);

  React.useEffect(() => {
    const stored = window.localStorage.getItem(persistenceKey);
    if (stored) {
      console.log(`Persisted data found`);
      try {
        const json = JSON.parse(stored);
        console.log(json);
        if (json) {
          const { user, project, projects, pageSize } = json;
          if (user) {
            setSignedInUser(user);
          }
          if (project) {
            setProjectCodeInUse(project);
          }
          if (projects) {
            setProjects(projects);
          }
          if (typeof pageSize === 'number') {
            setPageSize(pageSize)
          }
        }
      } catch (err) {}
    }
  }, []);

  const persist = (
    user: TSignedInUser | null,
    project: string,
    projects: TUserProject[],
    pageSize: number,
  ) => {
    const dataToPersist = {
      user,
      project,
      projects,
      pageSize
    };
    window.localStorage.setItem(persistenceKey, JSON.stringify(dataToPersist));
  };

  const updateSignedInUser = (user: TSignedInUser | null) => {
    setSignedInUser(user);
    persist(user, projectCodeInUse, projects, pageSize);
  };

  const updateProjectCode = (projectCode: string) => {
    console.log(`updateProjectCode: projectCode=${projectCode}`);
    setProjectCodeInUse(projectCode);
    persist(signedInUser, projectCode, projects, pageSize);
  };

  const updateProjects = (projects: TUserProject[]) => {
    setProjects(projects);
    persist(signedInUser, projectCodeInUse, projects, pageSize);
  };

  const updatePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    persist(signedInUser, projectCodeInUse, projects, pageSize);
  };

  const accessRight = React.useMemo(() => {
    if (signedInUser?.role) {
      return accessRights[
        signedInUser?.role as keyof {
          [role: string]: TAccessRights;
        }
      ];
    }
  }, [signedInUser?.role]);

  const {
    isLoading: isParticipantLoading,
    isFetching: isParticipantFetching,
    error: participantFetchError,
    data: participantData,
    refetch: refetchParticipant,
  } = useQuery(
    ["participant", participant?.email ?? ""],
    () =>
      fetch(api.getOrCreateParticipant, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          email: participant?.email,
        }),
      }).then((res) => res.json()),
    {
      enabled: !!participant && !!participant.email,
    }
  );

  const handleChangeUserEmail = (email: string) => {
    setParticipant({
      participantId: "",
      email,
    });
  };

  React.useEffect(() => {
    const { email, _id } = participantData ?? {};
    if (email && _id) {
      setParticipant({
        email,
        participantId: _id,
      });
    }
  }, [participantData]);

  return (
    <ThemeProvider theme={theme}>
      <SignedInUserContext.Provider
        value={{
          user: signedInUser,
          setUser: updateSignedInUser,
          projectCodeInUse,
          setProjectCodeInUse: updateProjectCode,
          projects,
          setProjects: updateProjects,
          pageSize,
          setPageSize: updatePageSize,
          accessRight,
        }}
      >
        <ParticipantContext.Provider
          value={{
            user: participant,
            fetching: isParticipantLoading || isParticipantFetching,
            changeEmail: handleChangeUserEmail,
            refetch: refetchParticipant,
          }}
        >
          <HashRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/task" element={<TaskPage />} />
              <Route path="/aboutus" element={<AboutUsPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/learn-more" element={<LearnMorePage />} />
              <Route path="/contactus" element={<ContactUsPage />} />
              <Route path="/documentation" element={<DocumentationPage />} />
              <Route path="/home" element={<MainMenuPage />} />
              <Route path="/login" element={<LoginPage />} />
              {signedInUser && (
                <React.Fragment>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/audiodata" element={<AudioDataPage />} />
                  <Route path="/textdata" element={<TextDataPage />} />
                  <Route path="/experiment" element={<ExperimentPage />} />
                  <Route path="/taskunit" element={<TaskUnitPage />} />
                  <Route path="/media" element={<MediaPage />} />
                  <Route path="/project" element={<ProjectPage />} />
                  <Route path="/user" element={<UserPage />} />
                </React.Fragment>
              )}
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </HashRouter>
        </ParticipantContext.Provider>
      </SignedInUserContext.Provider>
    </ThemeProvider>
  );
};

export default Integration;
