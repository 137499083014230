import { TAccessRights, TTask } from "./types";

const host = ".";

const api = {
  getParticipants: `${host}/api/participants`,
  getOrCreateParticipant: `${host}/api/participant`,
  getParticipantAudio: (participantId?: string) =>
    `${host}/api/audio-data/${participantId ?? ""}`,
  getMediaDataSize: ({ projectId = "" }) =>
    `${host}/api/media-total-size?` +
    new URLSearchParams({ projectId }).toString(),
  getAudioDataSize: ({ projectId = "" }) =>
    `${host}/api/audio-total-size?` +
    new URLSearchParams({ projectId }).toString(),
  getAudioData: ({
    projectId = "",
    page = "0",
    limit = "0",
    sortColumn = "",
    isSortedDesc = "false",
    search = "",
  }) =>
    `${host}/api/audio-data?` +
    new URLSearchParams({
      projectId,
      page,
      limit,
      sortColumn,
      isSortedDesc,
      search,
    }).toString(),
  getTextData: ({
    projectId = "",
    page = "0",
    limit = "0",
    sortColumn = "",
    isSortedDesc = "false",
    search = "",
  }) =>
    `${host}/api/text-data?` +
    new URLSearchParams({
      projectId,
      page,
      limit,
      sortColumn,
      isSortedDesc,
      search,
    }).toString(),
  downloadSingleTextData: ({ projectId = "", id = "" }) =>
    `${host}/api/download-single-text-data/${id}?` +
    new URLSearchParams({ projectId }).toString(),
  uploadAudioData: `${host}/api/audio-data`,
  uploadTextData: `${host}/api/text-data`,
  deleteAudioData: ({ projectId = "", id = "" }) =>
    `${host}/api/audio-data/${id}?` +
    new URLSearchParams({ projectId }).toString(),
  deleteTextData: ({ projectId = "", id = "" }) =>
    `${host}/api/text-data/${id}?` +
    new URLSearchParams({ projectId }).toString(),
  getSurveyData: ({ page = "0", limit = "0" }) =>
    `${host}/api/survey-data` +
    (page && limit
      ? "?" + new URLSearchParams({ page, limit }).toString()
      : ""),
  uploadSurveyData: `${host}/api/survey-data`,
  getDataCount: ({ projectId = "", participantId = "" }) =>
    `${host}/api/data-count/${participantId ?? ""}?` +
    new URLSearchParams({ projectId }).toString(),
  downloadAudioData: ({
    projectId = "",
    participantId,
    startDate = "",
    endDate = "",
    search = "",
  }: {
    projectId?: string;
    participantId?: string;
    startDate?: string;
    endDate?: string;
    search?: string;
  }) =>
    `${host}/api/download-audio-data/${participantId || ""}?` +
    new URLSearchParams({ projectId, startDate, endDate, search }).toString(),
  downloadAudioCSV: ({
    projectId = "",
    participantId,
    startDate = "",
    endDate = "",
    search = "",
  }: {
    projectId?: string;
    participantId?: string;
    startDate?: string;
    endDate?: string;
    search?: string;
  }) =>
    `${host}/api/download-audio-csv/${participantId || ""}?` +
    new URLSearchParams({ projectId, startDate, endDate, search }).toString(),
  downloadSingleAudioData: ({
    projectId = "",
    audioId = "",
  }: {
    projectId?: string;
    audioId?: string;
  }) =>
    `${host}/api/download-single-audio-data/${audioId}?` +
    new URLSearchParams({ projectId }).toString(),
  downloadSurveyData: ({
    participantId,
    startDate,
    endDate,
  }: {
    participantId?: string;
    startDate?: string;
    endDate?: string;
  }) =>
    `${host}/api/download-survey-data/${participantId ?? ""}` +
    (startDate && endDate
      ? "?" + new URLSearchParams({ startDate, endDate }).toString()
      : ""),
  downloadTextData: ({
    projectId = "",
    participantId,
    startDate = "",
    endDate = "",
    search = "",
  }: {
    projectId?: string;
    participantId?: string;
    startDate?: string;
    endDate?: string;
    search?: string;
  }) =>
    `${host}/api/download-text-data/${participantId ?? ""}?` +
    new URLSearchParams({ projectId, startDate, endDate, search }).toString(),
  getTaskUnits: ({
    projectId = "",
    code = "",
    language = "",
    mode = "",
    story = "",
  }) =>
    `${host}/api/taskunit-data?` +
    new URLSearchParams({ projectId, code, language, mode, story }).toString(),
  getTaskByCode: ({ taskCode = "" }) =>
    `${host}/api/task-data?` +
    new URLSearchParams({ code: taskCode }).toString(),
  checkDataAdequacy: ({ taskCode = "" }) =>
    `${host}/api/check-data-adequacy?` +
    new URLSearchParams({ code: taskCode }).toString(),
  getExperiments: ({ projectId = "", taskCode = "" }) =>
    `${host}/api/experiment-data?` +
    new URLSearchParams({ projectId, code: taskCode }).toString(),
  createExperiment: ({ projectId = "" }) =>
    `${host}/api/experiment-data?` +
    new URLSearchParams({ projectId }).toString(),
  updateExperiment: ({ projectId = "" }) =>
    `${host}/api/experiment-data?` +
    new URLSearchParams({ projectId }).toString(),
  deleteExperiment: ({ projectId = "", taskCode = "" }) =>
    `${host}/api/experiment-data/${taskCode}?` +
    new URLSearchParams({ projectId }).toString(),
  createTaskUnit: ({ projectId = "" }) =>
    `${host}/api/taskunit-data?` +
    new URLSearchParams({ projectId }).toString(),
  updateTaskUnit: ({ projectId = "" }) =>
    `${host}/api/taskunit-data?` +
    new URLSearchParams({ projectId }).toString(),
  deleteTaskUnit: ({ projectId = "", taskCode = "" }) =>
    `${host}/api/taskunit-data/${taskCode}?` +
    new URLSearchParams({ projectId }).toString(),
  getMediaData: ({
    projectId = "",
    code = "",
    page = "0",
    limit = "0",
    sortColumn = "",
    isSortedDesc = "false",
    search = "",
  }) =>
    `${host}/api/media/${code}?` +
    new URLSearchParams({
      projectId,
      page,
      limit,
      sortColumn,
      isSortedDesc,
      search,
    }).toString(),
  checkUserData: ({ username = "" }) => `${host}/api/user-check/${username}`,
  checkMediaData: ({ code = "" }) => `${host}/api/media-check/${code}`,
  changeMediaProject: ({ projectId = "" }) =>
    `${host}/api/media-project?` +
    new URLSearchParams({ projectId }).toString(),
  changeTaskUnitProject: ({ projectId = "" }) =>
    `${host}/api/taskunit-project?` +
    new URLSearchParams({ projectId }).toString(),
  changeExperimentProject: ({ projectId = "" }) =>
    `${host}/api/experiment-project?` +
    new URLSearchParams({ projectId }).toString(),
  checkProjectData: ({ code = "" }) => `${host}/api/project-check/${code}`,
  checkTaskUnitData: ({ code = "" }) => `${host}/api/taskunit-check/${code}`,
  checkExperimentData: ({ code = "" }) =>
    `${host}/api/experiment-check/${code}`,
  uploadMedia: ({ projectId = "" }) =>
    `${host}/api/upload?` + new URLSearchParams({ projectId }).toString(),
  updateMedia: ({ projectId = "" }) =>
    `${host}/api/media?` + new URLSearchParams({ projectId }).toString(),
  deleteMedia: ({ projectId = "", code = "" }) =>
    `${host}/api/media/${code}?` +
    new URLSearchParams({ projectId }).toString(),
  // getProjects: ({ code = '' }) => `${host}/api/project-data` + (code ? '?' + new URLSearchParams({ code }).toString() : ''),
  getProject: ({ projectId = "" }) => `${host}/api/project-data/${projectId}`,
  getProjects: ({
    page = "0",
    limit = "0",
    sortColumn = "",
    isSortedDesc = "false",
    search = "",
  }) =>
    `${host}/api/project-data` +
    (page && limit
      ? "?" +
      new URLSearchParams({
        page,
        limit,
        sortColumn,
        isSortedDesc,
        search,
      }).toString()
      : ""),
  createProject: () => `${host}/api/project-data`,
  updateProject: () => `${host}/api/project-data`,
  deleteProject: ({ code = "" }) => `${host}/api/project-data/${code}`,
  getUsers: ({
    page = "0",
    limit = "0",
    sortColumn = "",
    isSortedDesc = "false",
    search = "",
  }) =>
    `${host}/api/user-data` +
    (page && limit
      ? "?" +
      new URLSearchParams({
        page,
        limit,
        sortColumn,
        isSortedDesc,
        search,
      }).toString()
      : ""),
  getUsersByProject: ({
    projectId = "",
    page = "0",
    limit = "0",
    sortColumn = "",
    isSortedDesc = "false",
    search = "",
  }) =>
    `${host}/api/user-data-by-project` +
    (projectId
      ? "?" +
      new URLSearchParams({
        projectId,
        page,
        limit,
        sortColumn,
        isSortedDesc,
        search,
      }).toString()
      : ""),
  getUser: (username = "") => `${host}/api/user-data/${username}`,
  addUser: () => `${host}/api/user-data`,
  updateUser: () => `${host}/api/user-data`,
  deleteUser: ({ username = "" }) => `${host}/api/user-data/${username}`,
  changePassword: () => `${host}/api/user-password`,
  login: () => `${host}/api/login`,
};

const projectUrl =
  "https://www.leibniz-zas.de/fileadmin/media/Dokumente/ZASPiL/ZASPiL_63/ZASPiL_63_Background_on_MAIN.pdf";

const tasks: TTask[] = [
  {
    task: "survey",
    name: "Questionnaire",
    path: "/survey",
    hidden: true,
  },
];

const audioPath = "./audio/";
const mediaPath = "./files/";

const roles = [
  {
    id: "",
    label: "",
  },
  {
    id: "level1",
    label: "Lab assistant",
  },
  {
    id: "level2",
    label: "Lab manager",
  },
  {
    id: "level3",
    label: "Principal investigator",
  },
  {
    id: "level4",
    label: "System administrator",
  },
];

const accessRights: {
  [role: string]: TAccessRights;
} = {
  level1: {
    canViewMedia: false,
    canEditMedia: false,
    canDeleteMedia: false,
    canViewTaskUnits: false,
    canEditTaskUnits: false,
    canDeleteTaskUnits: false,
    canViewExperiments: true,
    canEditExperiments: false,
    canDeleteExperiments: false,
    canViewResponseData: true,
    canDeleteResponseData: false,
    canViewProjects: false,
    canEditProjects: false,
    canDeleteProjects: false,
    canManageProjectUsers: false,
    canManageAllProjects: false,
    canViewUsageReport: false,
  },
  level2: {
    canViewMedia: true,
    canEditMedia: true,
    canDeleteMedia: true,
    canViewTaskUnits: true,
    canEditTaskUnits: true,
    canDeleteTaskUnits: true,
    canViewExperiments: true,
    canEditExperiments: true,
    canDeleteExperiments: true,
    canViewResponseData: true,
    canDeleteResponseData: false,
    canViewProjects: false,
    canEditProjects: false,
    canDeleteProjects: false,
    canManageProjectUsers: false,
    canManageAllProjects: false,
    canViewUsageReport: true,
  },
  level3: {
    canViewMedia: true,
    canEditMedia: true,
    canDeleteMedia: true,
    canViewTaskUnits: true,
    canEditTaskUnits: true,
    canDeleteTaskUnits: true,
    canViewExperiments: true,
    canEditExperiments: true,
    canDeleteExperiments: true,
    canViewResponseData: true,
    canDeleteResponseData: true,
    canViewProjects: false,
    canEditProjects: false,
    canDeleteProjects: false,
    canManageProjectUsers: true,
    managedRoles: ["level1", "level2"],
    canManageAllProjects: false,
    canViewUsageReport: true,
  },
  level4: {
    canViewMedia: true,
    canEditMedia: true,
    canDeleteMedia: true,
    canViewTaskUnits: true,
    canEditTaskUnits: true,
    canDeleteTaskUnits: true,
    canViewExperiments: true,
    canEditExperiments: true,
    canDeleteExperiments: true,
    canViewResponseData: true,
    canDeleteResponseData: true,
    canViewProjects: true,
    canEditProjects: true,
    canDeleteProjects: true,
    canManageProjectUsers: true,
    managedRoles: ["level1", "level2", "level3", "level4"],
    canManageAllProjects: true,
    canViewUsageReport: true,
  },
};

const persistenceKey = "eduhk.dold.data";

const doldDocLinks = {
  preview:
    "https://drive.google.com/file/d/1JXo0wxuRNxzBDU10UG0e7aIZ0UlMNYfv/preview",
  view: "https://drive.google.com/file/d/1JXo0wxuRNxzBDU10UG0e7aIZ0UlMNYfv/view",
  download:
    "https://drive.google.com/uc?export=download&id=1JXo0wxuRNxzBDU10UG0e7aIZ0UlMNYfv",
};

const site = process.env.REACT_APP_SITE || "eduhk"

export {
  accessRights, api, audioPath, doldDocLinks, mediaPath, persistenceKey, projectUrl, roles, site, tasks
};

