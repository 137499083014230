import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import MicIcon from '@mui/icons-material/Mic';
import { Box, Paper, Stack, Typography } from '@mui/material';
import React from "react";
import { useQuery } from "react-query";
import {
  useNavigate
} from 'react-router-dom';
import ResponsiveAppBar from "../components/AppBar";
import DataCount from '../components/DataCount';
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import { SignedInUserContext } from '../contexts/SignedInUserContext';
import { api } from "../resources/config";
import { TSignedInUserWithSetUser } from '../resources/types';
import CurtainWithProgress from '../components/CurtainWithProgress';

const refreshInterval = 60000

function Dashboard() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const navigate = useNavigate()

  const projectId = React.useMemo(
    () => signedInUserWithSetUser?.projects?.find(v => v.code === signedInUserWithSetUser?.projectCodeInUse)?._id,
    [signedInUserWithSetUser?.projectCodeInUse, signedInUserWithSetUser?.projects]
  )

  const { isLoading, isFetching, error, data, refetch } = useQuery(
    ['data-count', projectId],
    () =>
      fetch(api.getDataCount({ projectId }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json()),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  const disabled = React.useMemo(() => {
    const disabledValue = isLoading || isFetching;
    console.log(
      `setting "disabled", isLoading=${isLoading}, isFetching = ${isFetching}, disabledValue=${disabledValue}`
    );
    return disabledValue;
  }, [isLoading, isFetching]);

  React.useEffect(
    () => {
      let timer: any = null
      const refresh = () => {
        if (!isLoading) {
          refetch()
          timer = setTimeout(refresh, refreshInterval)
        } else {
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(refresh, refreshInterval)
        }
      }
      refresh()
      return () => {
        timer && clearTimeout(timer)
      }
    },
    [isLoading, refetch]
  )

  const handleRefresh = () => {
    refetch()
  }

  // const handleClose = () => {
  //   setOpen(false)
  //   setSelectedAudio('')
  // }

  // const handleAudioItemClick = (audio: TAudioFile) => {
  //   setSelectedAudio(`${audioPath}${audio.filename}`)
  //   setOpen(true)
  // }

  const handleClickAudioData = () => {
    navigate('/audiodata')
  }

  const handleClickSurveyData = () => {
    navigate('/surveydata')
  }

  const handleClickTextData = () => {
    navigate('/textdata')
  }

  const projectFound = React.useMemo(() => {
    let found = false;
    if (
      signedInUserWithSetUser?.projectCodeInUse &&
      signedInUserWithSetUser?.projects &&
      signedInUserWithSetUser?.projects?.length > 0
    ) {
      if (
        signedInUserWithSetUser?.projects.find(
          (p) => p.code === signedInUserWithSetUser?.projectCodeInUse
        )
      ) {
        found = true;
      }
    }
    return found;
  }, [
    signedInUserWithSetUser?.projectCodeInUse,
    signedInUserWithSetUser?.projects,
  ]);

  return (
    <Paper sx={{ height: "100vh", overflow: 'scroll' }}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={['home', 'dashboard']} />
      <Stack sx={{ mt: 2 }}>
        <Box sx={{ m: 1, ml: 4, mr: 4 }}><ProjectInUseSelector readOnly={false} /></Box>
        <Typography variant="h3" sx={{ mt: 2, ml: 4, mr: 4 }}>
          Dashboard
        </Typography>

        {!projectFound && (
          <Typography variant="body1" sx={{ ml: 4, fontStyle: "italic" }}>
            A project is required
          </Typography>
        )}

        {projectFound && data && <Box display={'flex'} flexDirection="row" justifyContent={'flex-start'} sx={{ mt: 4, ml: 4 }} flexWrap={'wrap'}>
          {/*<DataCount icon={PeopleAltIcon} title="Participants" count={data.participantCount}/>*/}
          <DataCount icon={MicIcon} title="Audio data" count={data.audioDataCount} onClick={handleClickAudioData} />
          {/*<DataCount icon={AssignmentIcon} title="Survey data" count={data.surveyDataCount} onClick={handleClickSurveyData}/>*/}
          <DataCount icon={FormatAlignLeftIcon} title="Text data" count={data.textDataCount} onClick={handleClickTextData} />
        </Box>}
      </Stack>
      {disabled && <CurtainWithProgress open={disabled} />}
    </Paper>
  );
}

export default Dashboard;
