import jsPsychAudioButtonResponse from "@jspsych/plugin-audio-button-response";
import jsPsychAudioKeyboardResponse from "@jspsych/plugin-audio-keyboard-response";
import jsPsychCallFunction from "@jspsych/plugin-call-function";
import jsPsychHtmlAudioResponse from "@jspsych/plugin-html-audio-response";
import jsPsychHtmlButtonResponse from "@jspsych/plugin-html-button-response";
import jsPsychInitializeMicrophone from "@jspsych/plugin-initialize-microphone";
import { initJsPsych, JsPsych } from "jspsych";
import React from "react";
import { isIOS, isSafari } from "react-device-detect";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import jsPsychPreload from "@jspsych/plugin-preload";
import MicOffIcon from "@mui/icons-material/MicOff";
import { Box } from "@mui/material";
import { red } from "@mui/material/colors";
import CountdownTimer from "../components/CountdownTimer";
import CurtainWithProgress from "../components/CurtainWithProgress";
import RecordingMicIcon from "../components/RecordingMicIcon";
import TaskDone from "../components/TaskDone";
import UsernameHeader from "../components/TaskHeader";
import { ParticipantContext } from "../contexts/ParticipantContext";
import { applyAutoMax, extractAllMedia } from "../libraries/helper";
import pluginMap from "../libraries/pluginMap";
import { api } from "../resources/config";
import LoadingGraphic from "../resources/images/loading.gif";
import { renderTerm } from "../resources/lexicon";
import {
  TParticipantWithRefetch,
  TSkipFlowControl,
  TTaskUnit,
} from "../resources/types";
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";

function TaskPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSearchInspected, setUrlSearchInspected] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const confirm = useConfirmation();
  const snackbar = useSnackbar();
  const [showProgress, setShowProgress] = React.useState(false);
  const participantWithRefetch: TParticipantWithRefetch | null =
    React.useContext(ParticipantContext);
  const [participantId, setParticipantId] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [task, setTask] = React.useState<string | null>(null);
  const [data, setData] = React.useState<any>(null);
  const [done, setDone] = React.useState(false);
  const [steps, setSteps] = React.useState<string[]>([]);
  const [jsPsych, setJsPsych] = React.useState<JsPsych | null>(null);
  const [jsPsychTimeline, setJsPsychTimeline] = React.useState<any[] | null>(
    null
  );
  const [jsPsychStarted, setJsPsychStarted] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [experiment, setExperiment] = React.useState<any>(null);
  const [restartCount, setRestartCount] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);

  const [micFailure, setMicFailure] = React.useState("");

  const [trialTargetTimestamp, setTrialTargetTimestamp] = React.useState(0);

  // React.useEffect(
  //   () => {
  //     setExperiment(task)
  //   },
  //   []
  // )

  React.useEffect(() => {
    if (!urlSearchInspected) {
      const email = (searchParams.get("id") as string) ?? "";
      const task = (searchParams.get("task") as string) ?? "";
      // console.log(`useEffect #0: email=${email}, task=${task}`);
      if (email) {
        setEmail(email);
      }
      if (task) {
        setTask(task);
      }
      setSearchParams({});
      setUrlSearchInspected(true);
    }
  }, [searchParams, setSearchParams, urlSearchInspected]);

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: taskData,
    refetch,
  } = useQuery(
    ["task", task],
    () =>
      fetch(api.getTaskByCode({ taskCode: task ?? "" }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }).then((res) => {
        // console.log(`status code = ${res.status}, type = ${typeof res.status}`);
        if (res.status >= 200 && res.status < 400) {
          return res.json();
        } else {
          throw new Error("Error retrieving task definition");
        }
      }),
    {
      enabled: !!task,
    }
  );

  const { data: checkDataResults } = useQuery(
    ["check-data-adequacy", task],
    () =>
      fetch(
        api.checkDataAdequacy({
          taskCode: task ?? "",
        }),
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      ).then((res) => {
        return res.json();
      }),
    {
      enabled: !!task,
    }
  );

  React.useEffect(() => {
    if (checkDataResults) {
      console.log(
        `Data check results: ${JSON.stringify(checkDataResults, null, 2)}`
      );
    }
  }, [checkDataResults]);

  React.useEffect(() => {
    if (!taskData) {
      return;
    }
    if (taskData.taskUnits) {
      const allImages: string[] = [];
      const allVideos: string[] = [];
      const allAudio: string[] = [];
      taskData.taskUnits.forEach((v: TTaskUnit) => {
        // console.log(
        //   `plugin: type=${v.details?.type}, ${JSON.stringify(
        //     pluginMap[v.details?.type ?? ""],
        //     null,
        //     2
        //   )}`
        // );
        const { images, videos, audio } = extractAllMedia(v.details?.stimulus);
        // console.log(`From stimulus:`);
        // console.log(`images = ${JSON.stringify(images)}`);
        // console.log(`videos = ${JSON.stringify(videos)}`);
        // console.log(`audio = ${JSON.stringify(audio)}`);
        allImages.push(...images);
        allVideos.push(...videos);
        allAudio.push(...audio);
        const {
          images: images2,
          videos: videos2,
          audio: audio2,
        } = extractAllMedia(v.details?.prompt);
        // console.log(`From prompt:`);
        // console.log(`images2 = ${JSON.stringify(images2)}`);
        // console.log(`videos2 = ${JSON.stringify(videos2)}`);
        // console.log(`audio2 = ${JSON.stringify(audio2)}`);
        allImages.push(...images2);
        allVideos.push(...videos2);
        allAudio.push(...audio2);
        if (v.details?.preamble && v.details?.preamble !== "last_response") {
          const {
            images: images3,
            videos: videos3,
            audio: audio3,
          } = extractAllMedia(v.details?.preamble);
          // console.log(`From preamble:`);
          // console.log(`images3 = ${JSON.stringify(images3)}`);
          // console.log(`videos3 = ${JSON.stringify(videos3)}`);
          // console.log(`audio3 = ${JSON.stringify(audio3)}`);
          allImages.push(...images3);
          allVideos.push(...videos3);
          allAudio.push(...audio3);
        }
        if (pluginMap[v.details?.type ?? ""]?.useVideo && v.details?.stimulus) {
          // console.log(
          //   `useVideo true, may include stimulus: ${JSON.stringify(
          //     v.details?.stimulus
          //   )}`
          // );
          if (v.details?.stimulus instanceof Array) {
            // only add those without HTML tags, same for below
            // console.log(
            //   `stimuls array, ${JSON.stringify(
            //     v.details?.stimulus.filter((v: string) => !v.match(/<.*>/)),
            //     null,
            //     2
            //   )}`
            // );
            allVideos.push(
              ...v.details?.stimulus.filter((v: string) => !v.match(/<.*>/))
            );
          } else if (!v.details?.stimulus.match(/<.*>/)) {
            // console.log(
            //   `scalar stimulus, HTML tag result: ${!v.details?.stimulus.match(
            //     /<.*>/
            //   )}`
            // );
            allVideos.push(v.details?.stimulus);
          } else {
            // console.log(`HTML tags detected, will not include`);
          }
        } else if (
          pluginMap[v.details?.type ?? ""]?.useAudio &&
          v.details?.stimulus
        ) {
          // console.log(
          //   `useAudio true, may include stimulus: ${JSON.stringify(
          //     v.details?.stimulus
          //   )}`
          // );
          if (v.details?.stimulus instanceof Array) {
            // console.log(
            //   `stimuls array, ${JSON.stringify(
            //     v.details?.stimulus.filter((v: string) => !v.match(/<.*>/)),
            //     null,
            //     2
            //   )}`
            // );
            allAudio.push(
              ...v.details?.stimulus.filter((v: string) => !v.match(/<.*>/))
            );
          } else if (!v.details?.stimulus.match(/<.*>/)) {
            // console.log(
            //   `scalar stimulus, HTML tag result: ${!v.details?.stimulus.match(
            //     /<.*>/
            //   )}`
            // );
            allAudio.push(v.details?.stimulus);
          } else {
            // console.log(`HTML tags detected, will not include`);
          }
        }
      });
      console.log(
        `videos extracted: ${JSON.stringify(
          Array.from(new Set(allVideos)),
          null,
          2
        )}`
      );
      console.log(
        `images extracted: ${JSON.stringify(
          Array.from(new Set(allImages)),
          null,
          2
        )}`
      );
      console.log(
        `audio extracted: ${JSON.stringify(
          Array.from(new Set(allAudio)),
          null,
          2
        )}`
      );
      const exp = {
        preload: {
          videos: Array.from(new Set(allVideos)),
          images: Array.from(new Set(allImages)),
          audio: Array.from(new Set(allAudio)),
        },
        stages: taskData.taskUnits.map((v: any, index: number) => ({
          ...v.details,
          stimulus: applyAutoMax(v.details.stimulus),

          // store task and subtask in data object
          // which will be retrieved together with participant response during data upload/
          data: {
            task: taskData.code,
            subtask: v.code,
            skipFlowControl: taskData.skipFlowControls?.[index],
          },
        })),
        language: taskData.language,
        testMic: taskData.testMic,
        allowRestart: taskData.allowRestart,
      };
      setExperiment(exp);
    }
  }, [taskData]);

  React.useEffect(() => {
    // console.log(`useEffect #1: [jsPsych]`);
    if (!jsPsych) {
      // console.log(`useEffect #1: initialize jsPsych`);
      const j = initJsPsych({
        display_element: "jspsych-target",
        on_finish: function () {
          // console.log(this)
          // const thisJsPsych = (this.type as any).jsPsych as JsPsych
          // if (jsPsych) {
          //   setData((jsPsych as any)?.data?.get().values())
          //   setDone(true)
          // }
          setData(j?.data?.get()?.values());
          setDone(true);
        },
        on_trial_start: function (trial: any) {
          // console.log(`on_trial_start(): trial = %o`, trial);
          if (trial?.data?.showRecordingMic) {
            setIsRecording(true);
          } else {
            setIsRecording(false);
          }
        },
        on_trial_finish: function (data: any) {
          // alert('trial finished')
          // console.log("A trial just ended.");
          // console.log(this)
          // console.log(JSON.stringify(data));
          if (data.showRecordingMic) {
            setIsRecording(false);
          }
          if (data.task && data.subtask) {
            // console.log(`Both task and subtask exists`);
            // console.log(`init data`);
            // console.log(j?.data?.get()?.first()?.values());
          }
        },
      });
      setJsPsych(j);
    } else {
      // console.log(`useEffect #1: no need to initialize jsPsych`);
    }
  }, [jsPsych]);

  React.useEffect(() => {
    if (participantWithRefetch?.user?.participantId) {
      setParticipantId(participantWithRefetch?.user?.participantId);
      setEmail(participantWithRefetch?.user?.email);
    } else {
      navigate("/");
    }
  }, [navigate, participantWithRefetch]);

  // const advanceStep = React.useCallback(() => {
  //   console.log(`in advanceStep(), activeStep = ${activeStep}`)
  //   setActiveStep(activeStep + 1)
  // }, [activeStep])

  // const advanceStep = () => setActiveStep(activeStep + 1)

  const uploadAudio = React.useMemo(
    () => ({
      type: jsPsychCallFunction,
      async: true,
      func: async function (done: any) {
        // console.debug(`all data collected so far`)
        // console.debug(this.type)
        // console.debug(jsPsych.data.get().values())
        const thisJsPsych = (this.type as any).jsPsych as JsPsych;
        // console.log(thisJsPsych)
        // console.debug(thisJsPsych.data.get().values())
        const initData = thisJsPsych.data.get().first().values()[0];
        const { participantId } = initData;
        const lastTrialData = thisJsPsych.data.get().last().values()[0];
        // console.debug(`data to be uploaded`)
        // console.debug(lastTrialData)
        const { task, subtask, step, response, rt } = lastTrialData;
        setIsUploading(true);
        const audioFileId = await fetch(api.uploadAudioData, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            participantId,
            taskId: task,
            subtaskId: subtask,
            audio: response,
            responseTime: rt,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            // console.log(json);
            setIsUploading(false);
            return json.audioFileId;
          })
          .catch((error) => {
            setIsUploading(false);
            // console.error(error);
          });
        // setActiveStep(step)
        // advanceStep()

        if (step) {
          setActiveStep(step);
        }
        done({
          ...lastTrialData,
          response: undefined,
          audioFileId,
        });
      },
      // on_finish: function () {
      //   console.log(`in uploadAudio, on_finish()`)
      //   // advanceStep()
      //   if (step) {
      //     setActiveStep(step)
      //   }
      // }
    }),
    []
  );

  const uploadText = React.useMemo(
    () => ({
      type: jsPsychCallFunction,
      async: true,
      func: async function (done: any) {
        // console.debug(`all data collected so far`)
        // console.debug(this.type)
        // console.debug(jsPsych.data.get().values())
        const thisJsPsych = (this.type as any).jsPsych as JsPsych;
        // console.log(thisJsPsych)
        // console.debug(thisJsPsych.data.get().values())
        const initData = thisJsPsych.data.get().first().values()[0];
        const { participantId } = initData;
        const lastTrialData = thisJsPsych.data.get().last().values()[0];
        console.debug(`data to be uploaded`);
        console.debug(lastTrialData);
        const { task, subtask, step, response, rt } = lastTrialData;
        const textDataId = await fetch(api.uploadTextData, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            participantId,
            taskId: task,
            subtaskId: subtask,
            data: response,
            responseTime: rt,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            // console.log(json);
            return json.textDataId;
          })
          .catch((error) => {
            // console.error(error);
          });
        // setActiveStep(step)
        // advanceStep()

        if (step) {
          setActiveStep(step);
        }
        done({
          ...lastTrialData,
          response: undefined,
          textDataId,
        });
      },
      // on_finish: function () {
      //   console.log(`in uploadAudio, on_finish()`)
      //   // advanceStep()
      //   if (step) {
      //     setActiveStep(step)
      //   }
      // }
    }),
    []
  );

  React.useEffect(() => {
    // console.log(
    //   `useEffect #2: [experiment, jsPsych, jsPsychTimeline, participantId, uploadAudio]`
    // );
    if (!jsPsych || !experiment || !participantId || jsPsychTimeline) {
      // console.log(`useEffect #2: jsPsych = %o`, jsPsych);
      // console.log(`useEffect #2: jsPsychTimeline = %o`, jsPsychTimeline);
      // console.log(
      //   `useEffect #2: experiment=${JSON.stringify(
      //     experiment,
      //     null,
      //     2
      //   )}, participantId=${participantId}`
      // );
      // console.log(`useEffect #2: no need to initialize timeline`);
      return;
    }
    // console.log(`useEffect #2: initialize timeline`);

    const { preload, stages, language, testMic, allowRestart } = experiment;
    const { videos, images, audio } = preload;
    const initSteps = ["Init Mic"];

    const timeline: any[] = [];

    const preloadMedia = {
      type: jsPsychPreload,
      // auto_preload: true,
      video: videos,
      images,
      audio,
      data: {
        participantId,
      },
      on_start: function () {
        setShowProgress(true);
      },
    };
    timeline.push(preloadMedia);

    const hideProgress = {
      type: jsPsychHtmlButtonResponse,
      stimulus: "",
      choices: [],
      trial_duration: 50,
      on_start: function () {
        setShowProgress(false);
      },
      data: {
        participantId,
      },
    };

    const initMic = {
      type: jsPsychInitializeMicrophone,

      device_select_message: `<p>${renderTerm(
        language,
        "selectRecordingDeviceNote"
      )}</p>`,
      button_label: `${renderTerm(language, "selectThisDeviceNote")}`,
      on_start: function () {
        setShowProgress(false);
      },
      data: {
        participantId,
      },
    };

    const testMicNote = {
      type: jsPsychAudioKeyboardResponse,
      stimulus: renderTerm(language, "testRecordingMedia"),
      prompt: `<p>${renderTerm(language, "testMicNote")}</p>`,
      choices: "NO_KEYS",
      trial_ends_after_audio: true,
      on_start: function () {
        setShowProgress(false);
      },
    };

    const recordMic = {
      type: jsPsychHtmlAudioResponse,
      stimulus: `<span style='font-size: 24px;'>${renderTerm(
        language,
        "testMicRecordingNote"
      )}</span><br/>`,
      done_button_label: `${renderTerm(language, "listeningNote")}`,
      data: {
        showRecordingMic: true,
      },
      save_audio_url: true,
      recording_duration: 30000,
      on_finish: () => setActiveStep(1),
    };

    const playback = {
      type: jsPsychAudioButtonResponse,
      stimulus: () => {
        return jsPsych.data.get().last(1).values()[0].audio_url;
      },
      prompt: `<p>${renderTerm(language, "playingRecordingNote")}</p>`,
      choices: [
        `${renderTerm(language, "recordingOKNote")}`,
        `${renderTerm(language, "reselectRecordingDevice")}`,
      ],
    };

    const initMicLoop = {
      timeline: [initMic, testMicNote, recordMic, playback],
      loop_function: function (data: any) {
        // console.log(`data = `);
        // console.log(data);
        // console.log(`data.values() = `);
        // console.log(data.values());
        // console.log(`data.last(1).values() = `);
        // console.log(data.last(1).values());
        const key_response = data.last(1).values()[0].response;
        // console.log(`key_response = ${key_response}`);
        if (key_response === 0) {
          return false;
        } else {
          return true;
        }
      },
    };

    if (testMic) {
      timeline.push(initMicLoop);
    } else {
      // timeline.push(initGeneric);
      timeline.push(hideProgress);
    }

    let step = 2;
    stages.forEach((p: any) => {
      const { type } = p;
      if (type in pluginMap) {
        const plugin = pluginMap[type];
        if (!plugin) {
          return;
        }
        let timelineObject = {
          ...p,
          type: plugin.library,
          data: {
            ...p.data,
            step: 0,
          },
        };
        //
        // start of test skip
        // if (type === "jsPsychMediaHtmlButtonResponse") {
        //   console.log("adding conditional function");
        //   timelineObject = {
        //     timeline: [timelineObject],
        //     data: timelineObject.data,
        //     conditional_function: function () {
        //       console.log("inside conditional_function()");
        //       const lastData = jsPsych.data.get().last(2).values()[0];
        //       console.log(`lastData = ${JSON.stringify(lastData, null, 2)}`);
        //       if (lastData?.response === 1) {
        //         return false;
        //       } else {
        //         return true;
        //       }
        //     },
        //   };
        // }
        //
        // end of test skip

        if (plugin.advanceProgress) {
          initSteps.push("step");
          timelineObject.data.step = step;
          step++;
        }
        if (plugin.showRecordingMic) {
          timelineObject.data.showRecordingMic = true;
        }
        if (timelineObject.stimulus === "last_response") {
          // console.debug(`stimulus: Using last response stimulus`)
          timelineObject.stimulus = () =>
            `<audio autoplay><source src="${
              jsPsych.data.get().last(2).values()[0].audio_url
            }"></audio>` +
            (timelineObject.mediaPrompt
              ? `<p class="dold-media-prompt">${timelineObject.mediaPrompt}</p>`
              : "");
        }
        if (timelineObject.preamble === "last_response") {
          // console.debug(`preamble: Using last response stimulus`)
          timelineObject.preamble = () =>
            `<audio autoplay><source src="${
              jsPsych.data.get().last(2).values()[0].audio_url
            }"></audio>`;
        }
        if (plugin.iPadShowControl && (isSafari || isIOS)) {
          if (typeof timelineObject.stimulus === "string") {
            // console.log(
            //   `Patching for safari or iOS (origin): ${timelineObject.stimulus}`
            // );
            timelineObject.stimulus = timelineObject.stimulus?.replace(
              /<video /g,
              "<video controls "
            );
            // console.log(
            //   `Patching for safari or iOS (patched): ${timelineObject.stimulus}`
            // );
          }
          timelineObject.controls = true;
        }

        timelineObject.on_start = function (...arg: any[]) {
          // console.log(`on_start: %o`, arg);
          // console.log(this);
          const duration =
            timelineObject.trial_duration ?? timelineObject.recording_duration;
          if (duration > 0 && !timelineObject.allow_playback) {
            const targetTimestamp = new Date().getTime() + duration;
            console.log(`time up at: ${new Date(targetTimestamp)}`);
            setTrialTargetTimestamp(targetTimestamp);
            setTimeout(() => {
              console.log("times up");
              console.log(`time up: ${new Date()}`);
            }, duration);
          }
        };
        timelineObject.on_finish = function (...arg: any[]) {
          // console.log(`on_finish: %o`, arg);
          setTrialTargetTimestamp(0);
          // console.log(this);
        };

        if (!!p.data.skipFlowControl?.enabled) {
          const { lastNthElement, equalityCheck, targetValue } = p.data
            .skipFlowControl as TSkipFlowControl;
          if (
            typeof lastNthElement === "number" &&
            typeof equalityCheck === "boolean" &&
            typeof targetValue === "string"
          ) {
            console.debug("adding conditional function for skip flowcontrol");

            const conditionalTimeline = [timelineObject];
            if (plugin.uploadText) {
              conditionalTimeline.push(uploadText);
            } else {
              conditionalTimeline.push(uploadAudio);
            }
            timelineObject = {
              timeline: conditionalTimeline,
              data: { ...timelineObject.data },
              conditional_function: function () {
                console.debug(
                  `inside conditional_function(), lastNthElement=${lastNthElement}, equalityCheck=${equalityCheck}, targetValue=${targetValue}`
                );
                const jsPsychData = jsPsych.data.get();
                console.log("jsPsychData:");
                console.log(jsPsychData);
                const lastTrialData = jsPsych.data
                  .getLastTrialData()
                  .values()[0];
                console.log("Last trial data:");
                console.log(JSON.stringify(lastTrialData, null, 2));
                // const lastTimeLineData = jsPsych.data.getLastTimelineData()
                // console.log("Last timeline data:")
                // console.log(JSON.stringify(JSON.parse(lastTimeLineData.json()), null, 2))
                // const interactionData = jsPsych.data.getInteractionData()
                // console.log("Interaction data:")
                // console.log(JSON.stringify(JSON.parse(interactionData.json()), null, 2))
                // const allData = jsPsych.data.get()
                // console.log(JSON.stringify(JSON.parse(allData.json()), null, 2))
                // console.log("All data:")
                // const lastMinusOneData = jsPsych.data.get().last(lastNthElement * 2 - 1).values()[0];
                const lastData = jsPsych.data
                  .get()
                  .last(lastNthElement * 2)
                  .values()[0];
                // const lastPlusOneData = jsPsych.data.get().last(lastNthElement * 2 + 1).values()[0];
                // console.debug(`lastMinusOneData = ${JSON.stringify(lastMinusOneData, null, 2)}`);
                console.debug(
                  `lastData = ${JSON.stringify(lastData, null, 2)}`
                );
                // console.debug(`lastPlusOneData = ${JSON.stringify(lastPlusOneData, null, 2)}`);
                let valueToCheck = lastData.response;

                if (typeof lastData.response === "object") {
                  // for questionnaire responses, response has value like {"Q0": "Apple", "Q1": "Others"}
                  // Only check the last value due to simplicity
                  const valuesToCheck = Object.values(lastData.response);
                  valueToCheck = valuesToCheck[valuesToCheck.length - 1];
                }

                console.log(`valueToCheck = ${valueToCheck}`);

                if (
                  (equalityCheck &&
                    lastData &&
                    String(valueToCheck) === targetValue) ||
                  (!equalityCheck &&
                    lastData &&
                    String(valueToCheck) !== targetValue)
                ) {
                  jsPsych.data.get().push({
                    type: "flow-control-skipped",
                  });
                  jsPsych.data.get().push({
                    type: "flow-control-upload-skipped",
                  });
                  if (timelineObject.data.step) {
                    setActiveStep(timelineObject.data.step);
                  }
                  console.debug(
                    `task unit skipped. Need to advance step up to this point`
                  );
                  return false;
                } else {
                  return true;
                }
              },
            };
          } else {
            console.debug(
              `Illegal value types of skipFlowControl: ${JSON.stringify(
                p.data.skipFlowControl,
                null,
                2
              )}`
            );
          }

          timeline.push(timelineObject);
        } else {
          timeline.push(timelineObject);
          if (plugin.uploadText) {
            timeline.push(uploadText);
          } else {
            timeline.push(uploadAudio);
          }
        }
      }
    });

    setSteps(initSteps);

    // jsPsych.run(timeline);
    setJsPsychTimeline(timeline);
    if (testMic) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          // console.log(`Microphone detected. Returned stream:`);
          // console.log(stream);
          setJsPsychStarted(true);
          // throw new Error("NotFoundError");
        })
        .catch((err) => {
          const errorMessage = (err as Error).toString();
          if (
            errorMessage.match(/NotFoundError/i) ||
            errorMessage.match(/Requested device not found/i)
          )
            setMicFailure(
              "Audio recording device not found. Please connect a microphone and try again."
            );
          else {
            setMicFailure(`Failed to access recording device: ${errorMessage}`);
          }
        });
    } else {
      setJsPsychStarted(true);
    }
  }, [
    experiment,
    jsPsych,
    jsPsychTimeline,
    participantId,
    uploadAudio,
    uploadText,
  ]);

  React.useEffect(() => {
    if (jsPsych && jsPsychStarted && jsPsychTimeline) {
      // alert('will start jspsych')
      jsPsych.run(jsPsychTimeline);
    }
  }, [jsPsych, experiment, jsPsychStarted, jsPsychTimeline]);

  const handleRestart = () => {
    const { language } = experiment;
    confirm({
      variant: "danger",
      title: renderTerm(language, "startOver"),
      description: renderTerm(language, "startOverConfirmNote"),
      cancelButtonText: renderTerm(language, "startOverCancel"),
      agreeButtonText: renderTerm(language, "startOverConfirmOK"),
    }).then(() => {
      // console.log("Stop all audio playing");
      document.querySelectorAll("audio").forEach((el) => el.pause());
      if (typeof jsPsych?.webaudio_context?.suspend === "function") {
        jsPsych.webaudio_context.suspend();
      }
      jsPsych && jsPsych.endExperiment();
      // increment restartcount to re-initialize <div key={restartCount} id="jspsych-target" />
      setRestartCount(restartCount + 1);

      // after div is destroyed and re-created, initialize jspsych from scratch
      setTimeout(() => {
        setJsPsych(null);
        setData(null);
        setDone(false);
        setJsPsychTimeline(null);
        setJsPsychStarted(false);
        setActiveStep(0);
      }, 100);
    });
  };

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Box
      component="div"
      className="App"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: { xs: "column" },
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={(event: React.MouseEvent) => {
        try {
          const jspsychDiv = document.getElementById("jspsych-target");
          if (!jspsychDiv?.contains(event.target as Node)) {
            jspsychDiv?.focus();
          }
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {!isError &&
        !checkDataResults?.audioDataQuotaExceeded &&
        email &&
        !showProgress &&
        !isLoading &&
        !micFailure && (
          <UsernameHeader
            isLoading={showProgress || isLoading}
            username={email}
            language={experiment?.language}
            onBackClick={handleGoBack}
            allowRestart={!!experiment?.allowRestart}
            onRestart={handleRestart}
            steps={steps}
            activeStep={activeStep}
          />
        )}
      {!isError && !checkDataResults?.audioDataQuotaExceeded && (
        <Box
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ visibility: "hidden", width: 0, height: 0 }}>
              <audio preload="auto">
                <source src="media/pthTestMic.m4a" type="audio/mp4" />
              </audio>
              <audio preload="auto">
                <source src="media/ctnTestMic.m4a" type="audio/mp4" />
              </audio>
              <audio preload="auto">
                <source src="media/engTestMic.m4a" type="audio/mp4" />
              </audio>
            </Box>

            {!!participantId && !done && (
              <div
                key={restartCount}
                id="jspsych-target"
                style={{ textAlign: "center" }}
              ></div>
            )}
            {(showProgress || isLoading) && (
              <img src={LoadingGraphic} alt="Loading..." width="750" />
            )}
            <RecordingMicIcon
              language={experiment?.language}
              isRecording={isRecording}
            />
            {!!participantId && done && data && (
              <TaskDone
                heading={renderTerm(experiment.language, "taskDone")}
                message={renderTerm(experiment?.language, "taskDoneThanks")}
              />
            )}
            {/*isLoading && <CurtainWithProgress />*/}
            {!!micFailure && (
              <Box
                sx={{ fontSize: "large", color: red[600], textAlign: "center" }}
              >
                <Box>
                  <MicOffIcon sx={{ color: red[600], fontSize: 64 }} />
                </Box>
                {micFailure}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {!isError &&
        !checkDataResults?.audioDataQuotaExceeded &&
        email &&
        !showProgress &&
        !isLoading &&
        !micFailure && (
          <Box sx={{ width: "100%" }}>
            {trialTargetTimestamp > 0 && (
              <CountdownTimer targetTimestamp={trialTargetTimestamp} />
            )}
          </Box>
        )}
      {isError && !!error && (
        <Box sx={{ fontSize: "xx-large", color: red[600] }}>
          {JSON.stringify(error)}
        </Box>
      )}
      {checkDataResults?.audioDataQuotaExceeded && (
        <Box sx={{ fontSize: "xx-large", color: red[600] }}>
          Data collection storage is full.
        </Box>
      )}
      {isUploading && <CurtainWithProgress open={isUploading} />}
    </Box>
  );
}

export default TaskPage;
