import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useTable } from "react-table";
import ResponsiveAppBar from "../components/AppBar";
import CurtainWithProgress from "../components/CurtainWithProgress";
import ErrorDisplay from "../components/ErrorDisplay";
import ItemTitleInEdit from "../components/ItemTitleInEdit";
import MediaEditor from "../components/MediaEditor";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import ProjectChanger from "../components/ProjectChanger";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import RenderMedia from "../components/RenderMedia";
import StorageUsageDisplay from "../components/StorageUsageDisplay";
import TypeIcon from "../components/TypeIcon";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { formatBytes } from "../libraries/helper";
import { api, mediaPath } from "../resources/config";
import { TMedia, TSignedInUserWithSetUser } from "../resources/types";
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";
import usePageSize from "../hooks/usePageSize";
import PaginationControl from "../components/PaginationControl";

function MediaPage() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [mediaForEdit, setMediaForEdit] = React.useState<TMedia | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [createNew, setCreateNew] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { pageSize, setPageSize } = usePageSize();
  const [sortColumn, setSortColumn] = React.useState("");
  const [isSortedDesc, setIsSortedDesc] = React.useState<boolean | undefined>(
    false
  );
  const [mediaDataFilter, setMediaDataFilter] = React.useState("");
  const [search, setSearch] = React.useState("");
  const searchTextField = React.useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();
  const confirm = useConfirmation();
  const [isProjectChangeOpen, setIsProjectChangeOpen] = React.useState(false);
  const [mediaCodeForProjectChange, setMediaCodeForProjectChange] =
    React.useState("");
  const [originalProjectIds, setOriginalProjectIds] = React.useState<string[]>(
    []
  );

  const projectId = React.useMemo(
    () =>
      signedInUserWithSetUser?.projects?.find(
        (v) => v.code === signedInUserWithSetUser?.projectCodeInUse
      )?._id,
    [
      signedInUserWithSetUser?.projectCodeInUse,
      signedInUserWithSetUser?.projects,
    ]
  );

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: mediaData,
    refetch: refetchMediaData,
  } = useQuery(
    [
      "media-data",
      projectId,
      currentPage,
      pageSize,
      sortColumn,
      isSortedDesc,
      search,
    ],
    () =>
      fetch(
        api.getMediaData({
          projectId,
          page: currentPage.toString(),
          limit: pageSize.toString(),
          sortColumn: sortColumn || "",
          isSortedDesc: isSortedDesc?.toString() || "false",
          search,
        }),
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        }
      ).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  const { data: mediaDataSize, refetch: refetchMediaDataSize } = useQuery(
    ["media-data-size", projectId, mediaData?.totalDocs],
    () =>
      fetch(
        api.getMediaDataSize({
          projectId,
        }),
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        }
      ).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  const { data: projectData, refetch: refetchProjectData } = useQuery(
    ["project-data", projectId],
    () =>
      fetch(
        api.getProject({
          projectId,
        }),
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        }
      ).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  React.useEffect(() => {
    if (isError) {
      console.log(`isError: ${error}`);
      if ((error as Error).message === "Unauthorized") {
        signedInUserWithSetUser?.setUser(null);
      }
    }
  }, [isError, error, signedInUserWithSetUser]);

  React.useEffect(() => {
    setTimeout(() => searchTextField.current?.focus(), 100);
  }, [mediaData]);

  const handleEdit = React.useCallback(
    (code: string) => {
      console.log(`handleEdit(): code = ${code}`);
      const media = mediaData?.docs?.find((v: TMedia) => v.code === code);
      if (media) {
        setCreateNew(false);
        setMediaForEdit({ ...media });
      }
    },
    [mediaData?.docs]
  );

  const handleProjectChange = React.useCallback(
    (code: string) => {
      const media = mediaData?.docs?.find((v: TMedia) => v.code === code);
      if (!media) {
        return;
      }
      setMediaCodeForProjectChange(code);
      setOriginalProjectIds(media.project ?? []);
      setIsProjectChangeOpen(true);
    },
    [mediaData?.docs]
  );

  const handleProjectChangeConfirm = async (projectCodes: string[]) => {
    console.log(`handleProjectChangeConfirm: ${JSON.stringify(projectCodes)}`);
    try {
      const json = await fetch(api.changeMediaProject({ projectId }), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
        body: JSON.stringify({
          projectCodes,
          mediaCodes: [mediaCodeForProjectChange],
        }),
      }).then((res) => res.json());
      console.log(`response`);
      console.log(json);
      if (json.error) {
        throw new Error(json.message || json.error);
      }
      setIsProcessing(false);
      snackbar({
        severity: "success",
        message:
          "Project designation of media item has been successfully updated.",
      });
      setMediaCodeForProjectChange("");
      setOriginalProjectIds([]);
      setIsProjectChangeOpen(false);
      refetchMediaData();
      refetchMediaDataSize();
      refetchProjectData();
    } catch (err) {
      setIsProcessing(false);
      confirm({
        variant: "error",
        title: `Error changing project designation for media "${mediaCodeForProjectChange}"`,
        description:
          (err as Error)?.toString() ??
          "Error updating this media item. Please check and try again.",
        agreeButtonText: "OK",
      });
    }
  };

  const columns = React.useMemo(() => {
    console.log(`memoizing columns`);
    return [
      {
        Header: "Type",
        accessor: "mimeTypeMajor",
        width: "5%",
        Cell: ({ value }: { value: any }) => {
          return <TypeIcon type={value} />;
        },
      },
      {
        Header: "Media ID",
        accessor: "code",
        width: "10%",
      },
      {
        Header: "Note",
        accessor: "note",
        width: "15%",
      },
      {
        Header: "Preview",
        accessor: "filename",
        Cell: ({ row, value }: { row: any; value: any }) => {
          const { mimeTypeMajor, url, thumbnailFilename, thumbnailUrl } =
            row.original;
          return (
            <RenderMedia
              size="small"
              type={mimeTypeMajor}
              filename={value}
              url={url}
              thumbnailFilename={thumbnailFilename}
              thumbnailUrl={thumbnailUrl}
            />
          );
        },
        width: "15%",
      },
      {
        Header: "Size",
        accessor: "fileSize",
        Cell: ({ row, value }: { row: any; value: any }) => {
          const { fileSize } = row.original;
          return formatBytes(fileSize, 1);
        },
        width: "8%",
      },
      {
        Header: "Uploaded",
        accessor: "uploadedDate",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        width: "15%",
      },
      {
        Header: "Modified",
        accessor: "modifiedDate",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        width: "15%",
      },
      {
        Header: "",
        id: "actions",
        width: "15%",
        Cell: ({ row, value }: { row: any; value: string }) => {
          const { code, filename, url } = row.original;
          const src = url ? url : `${mediaPath}/${filename}`;
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {src && (
                <Tooltip title="Download">
                  <a
                    href={src}
                    download={filename}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon
                      sx={{ verticalAlign: "middle", m: 1, p: 1 }}
                    />
                  </a>
                </Tooltip>
              )}
              {code && (
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEdit(code)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {code &&
                signedInUserWithSetUser?.accessRight?.canManageAllProjects && (
                  <Tooltip title="Change project designation">
                    <IconButton onClick={() => handleProjectChange(code)}>
                      <AccountTreeIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
          );
        },
      },
    ];
  }, [handleEdit, handleProjectChange, signedInUserWithSetUser?.accessRight]);

  const handleSort = (newSortColumn: string, newIsSortedDesc?: boolean) => {
    console.log(
      `in handleSort(),newSortColumn=${newSortColumn}, newIsSortedDesc=${newIsSortedDesc} `
    );
    setSortColumn(newSortColumn);
    setIsSortedDesc(newIsSortedDesc);
  };

  const handleCreateAndUploadNewMedia = () => {
    console.log(`handleCreateAndUploadNewMedia`);
    setCreateNew(true);
    setMediaForEdit({
      code: "",
      note: "",
      filename: "",
      mimeType: "",
      mimeTypeMajor: "",
      uploadedDate: new Date(),
      modifiedDate: new Date(),
    });
  };

  const handleMediaChange = (media: TMedia) => {
    console.log(`handleMediaChange(), media = ${JSON.stringify(media)}`);
    setMediaForEdit(media);
  };

  const handleMediaDelete = () => {
    if (!mediaForEdit?.code) {
      return;
    }
    const { code } = mediaForEdit;
    console.log(`handleMediaDelete() code=${code}`);
    confirm({
      variant: "danger",
      title: `Delete media "${code}"`,
      description: `Are you sure to delete item "${code}"?`,
      cancelButtonText: "No",
      agreeButtonText: "Yes, go ahead to delete",
      catchOnCancel: true,
    })
      .then(() => {
        console.log(`Go ahead to delete ${code}`);
        fetch(api.deleteMedia({ projectId, code }), {
          method: "DELETE",
          credentials: "include",
          headers: {
            // "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            snackbar({
              severity: "success",
              message: `Media "${code}" is deleted successfully`,
            });
            setMediaForEdit(null);
            refetchMediaData();
            refetchMediaDataSize();
            refetchProjectData();
          })
          .catch((error) => {
            snackbar({
              severity: "error",
              message: `Deletion of media "${code}" failed: ${(
                error as Error
              )?.toString()}`,
            });
          });
      })
      .catch(() => {
        snackbar({
          severity: "info",
          message: `Deletion of media "${code}" cancelled`,
        });
      });
  };

  const handleMediaSave = async ({
    code,
    note,
    file,
  }: {
    code: string;
    note: string;
    file?: File;
  }) => {
    console.log(`handleMediaSave(): code=${code},note=${note},file=`);
    console.log(file);
    const data = new FormData();
    data.append("code", code);
    data.append("note", note);
    if (file) {
      data.append("file", file);
    }
    setIsProcessing(true);
    try {
      const json = await fetch(
        createNew
          ? api.uploadMedia({ projectId })
          : api.updateMedia({ projectId }),
        {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
          body: data,
        }
      ).then((res) => res.json());
      console.log(`response`);
      console.log(json);
      if (json.error) {
        throw new Error(json.message || json.error);
      }
      setIsProcessing(false);
      snackbar({
        severity: "success",
        message: createNew
          ? "New media item has been successfully created"
          : "Media item has been successfully updated.",
      });
      setMediaForEdit(null);
      refetchMediaData();
      refetchMediaDataSize();
      refetchProjectData();
    } catch (err) {
      setIsProcessing(false);
      confirm({
        variant: "error",
        title: `Error saving media "${code}"`,
        description:
          (err as Error)?.toString() ??
          "Error saving this media item. Please check and try again.",
        agreeButtonText: "OK",
      });
    }
  };

  const handleCancel = () => {
    setMediaForEdit(null);
  };

  const handleMediaDataFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMediaDataFilter(event.target.value);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleMediaDataFilterClear = () => {
    setMediaDataFilter("");
    setCurrentPage(1);
    setSearch("");
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(mediaDataFilter);
  };

  const disabled = React.useMemo(
    () => isLoading || isFetching || isProcessing,
    [isLoading, isFetching, isProcessing]
  );

  const filteredMediaData = React.useMemo(
    () => {
      // const mediaDataFilterKeywords = mediaDataFilter.trim().split(/\s+/)
      // const filteredMediaData = mediaData?.docs.filter((v: TMedia) => !mediaDataFilter ||
      //   matchKeywords([v.code, v.note, v.mimeTypeMajor], mediaDataFilterKeywords))
      // return {
      //   ...mediaData,
      //   docs: filteredMediaData
      // }
      return {
        ...mediaData,
      };
    },
    // [mediaData, mediaDataFilter]
    [mediaData]
  );

  const projectFound = React.useMemo(() => {
    let found = false;
    if (
      signedInUserWithSetUser?.projectCodeInUse &&
      signedInUserWithSetUser?.projects &&
      signedInUserWithSetUser?.projects?.length > 0
    ) {
      if (
        signedInUserWithSetUser?.projects.find(
          (p) => p.code === signedInUserWithSetUser?.projectCodeInUse
        )
      ) {
        found = true;
      }
    }
    return found;
  }, [
    signedInUserWithSetUser?.projectCodeInUse,
    signedInUserWithSetUser?.projects,
  ]);

  return (
    <Paper elevation={0} sx={{ height: "100vh", overflow: "auto" }}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "media"]} />
      <Stack sx={{ mt: 2 }}>
        <Box sx={{ m: 1, ml: 4, mr: 4 }}>
          <ProjectInUseSelector readOnly={!!mediaForEdit} />
        </Box>
        <Box sx={{ mt: 2, ml: 4 }}>
          {mediaForEdit ? (
            <ItemTitleInEdit
              prefix={createNew ? `Upload new media` : `Edit media`}
              itemTitle={mediaForEdit.code}
            />
          ) : (
            <Typography variant="h3">Media</Typography>
          )}
        </Box>
        {projectFound &&
          !isError &&
          signedInUserWithSetUser?.accessRight?.canEditMedia &&
          !mediaForEdit && (
            <Box>
              {mediaDataSize && projectData && (
                <Box sx={{ ml: 4 }}>
                  {projectData.mediaSizeLimit > 0 ? (
                    <Typography variant="body1">
                      {`Total size: ${formatBytes(
                        mediaDataSize.totalSize,
                        1
                      )}, out of ${formatBytes(
                        projectData.mediaSizeLimit,
                        1
                      )}`}
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      {`Total size: ${formatBytes(
                        mediaDataSize.totalSize,
                        1
                      )}`}
                    </Typography>
                  )}
                  {mediaDataSize.totalSize &&
                    projectData.mediaSizeLimit > 0 && (
                      <StorageUsageDisplay
                        usage={formatBytes(mediaDataSize.totalSize, 1)}
                        value={
                          (mediaDataSize.totalSize * 100) /
                          projectData.mediaSizeLimit
                        }
                      />
                    )}
                </Box>
              )}
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
                sx={{ ml: 4 }}
              >
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleCreateAndUploadNewMedia}
                >
                  Upload new media item
                </Button>
              </Box>
            </Box>
          )}
        {!projectFound && (
          <Typography variant="body1" sx={{ ml: 4, fontStyle: "italic" }}>
            A project is required
          </Typography>
        )}
        {isError && (
          <Box sx={{ mt: 4, ml: 4, mr: 4, p: 4 }}>
            <ErrorDisplay error={(error as Error).toString()} />
          </Box>
        )}
        {!isError && mediaData && !mediaForEdit && (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="body1">
                Browse the media items, or search with keywords.
              </Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              margin="none"
              size="small"
              inputRef={searchTextField}
              value={mediaDataFilter}
              onChange={handleMediaDataFilterChange}
              onKeyPress={handlePressEnter}
              placeholder={`e.g. "cantonese cat video"`}
              helperText={
                'Input some keywords to begin searching. Include optional keyword "video", "image" or "audio" to specify the media type'
              }
              InputProps={{
                endAdornment: [
                  <InputAdornment position="end" key="clear">
                    <IconButton onClick={handleMediaDataFilterClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>,
                  <Divider
                    key="divider"
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />,
                  <InputAdornment position="end" key="search">
                    <IconButton color="primary" onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>,
                ],
              }}
            />
          </Box>
        )}

        {!isError &&
          mediaData &&
          !mediaForEdit &&
          mediaData.totalDocs === 0 && (
            <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
              <Typography variant="body1">No media items found</Typography>
            </Box>
          )}

        {!isError && mediaData && !mediaForEdit && mediaData.totalDocs > 0 && (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="caption" sx={{ mr: 4 }}>
                  {`Total: ${mediaData.totalDocs} records.` +
                    ` Record #${(mediaData.page - 1) * mediaData.limit + 1
                    } to #${(mediaData.page - 1) * mediaData.limit +
                    mediaData.docs.length
                    }`}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-end">
                <PaginationControl pageSize={pageSize} onPageSizeChange={setPageSize} sx={{ mr: 8 }} />
                <Button
                  startIcon={<ArrowBackIcon />}
                  disabled={!mediaData.hasPrevPage}
                  onClick={() => setCurrentPage(mediaData.prevPage)}
                >
                  Previous
                </Button>
                <Button
                  startIcon={<ArrowForwardIcon />}
                  disabled={!mediaData.hasNextPage}
                  onClick={() => setCurrentPage(mediaData.nextPage)}
                >
                  Next
                </Button>
              </Box>
            </Box>
            <MediaDataTable
              columns={columns}
              data={filteredMediaData.docs}
              sortColumn={sortColumn}
              isSortedDesc={isSortedDesc}
              onSort={handleSort}
            />
          </Box>
        )}
        {!isError && mediaData && mediaForEdit && (
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-between"}
            sx={{ mr: 24 }}
          >
            <Box sx={{ ml: 4 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={4}
              >
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setMediaForEdit(null)}
                >
                  Back to media list
                </Button>
              </Box>
              <MediaEditor
                disabled={disabled}
                createNew={createNew}
                media={mediaForEdit}
                onSave={handleMediaSave}
                onCancel={handleCancel}
                onChange={handleMediaChange}
                onDelete={handleMediaDelete}
              />
            </Box>
          </Box>
        )}
      </Stack>
      <ProjectChanger
        show={isProjectChangeOpen}
        onClose={() => setIsProjectChangeOpen(false)}
        onCancel={() => setIsProjectChangeOpen(false)}
        selectedProjectIds={originalProjectIds}
        title={`Change project designation for ${mediaCodeForProjectChange}`}
        helperText={
          "Designate the projects that will have access to this media item"
        }
        onConfirm={handleProjectChangeConfirm}
      />
      {disabled && !error && <CurtainWithProgress open={disabled} />}
    </Paper>
  );
}

const MediaDataTable = ({
  columns,
  data,
  onSort,
  sortColumn,
  isSortedDesc,
}: {
  columns: any;
  data: any;
  onSort: (sortColumn: string, isSortedDesc?: boolean) => void;
  sortColumn: string;
  isSortedDesc: boolean | undefined;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              // console.log(`Current Header, column.id = ${column.id}`)
              // console.log(`Current Header, column.isSorted = ${column.isSorted}`)
              // console.log(`Current Header, column.isSortedDesc = ${column.isSortedDesc}`)
              return (
                <TableCell
                  {...column.getHeaderProps()}
                  onClick={(event) => {
                    // console.log("Header clicked, event = %o", event)
                    console.log(`Header clicked, column = %o`, column);
                    console.log(`Header clicked, column.id = ${column.id}`);
                    // console.log(`Header clicked, column.isSorted = ${column.isSorted}`)
                    // console.log(`Header clicked, column.isSortedDesc = ${column.isSortedDesc}`)
                    // if (!column.isSorted) {
                    //   // pass on next values, not current values
                    //   onSort(column.id, typeof column.isSortedDesc === 'undefined' ? false : !column.isSortedDesc)
                    // } else {
                    //   onSort('')
                    // }
                    if (column.id === sortColumn && isSortedDesc) {
                      onSort("");
                    } else if (
                      column.id === sortColumn &&
                      isSortedDesc === false
                    ) {
                      onSort(column.id, true);
                    } else {
                      onSort(column.id, false);
                    }
                  }}
                  sx={{ width: column.width, cursor: "pointer" }}
                >
                  {column.render("Header")}
                  <span>
                    {column.id === sortColumn
                      ? isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <TableCell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default MediaPage;
