import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Typography } from '@mui/material';
import { blue, orange } from '@mui/material/colors';
import { Roll } from "react-awesome-reveal";

const TaskDone = ({heading = '', message = ''}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {heading && <Typography variant="h3" sx={{ margin: 2, color: blue[900] }}>{heading}</Typography>}
      <Roll><ThumbUpIcon sx={{ fontSize: 64, margin: 2, color: orange[700] }} /></Roll>
      {message && <Typography variant="h5" sx={{ margin: 2, color: blue[700] }}>{message}</Typography>}
    </Box>
  )
}

export default TaskDone