import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import InboxIcon from "@mui/icons-material/Inbox";
import MovieIcon from "@mui/icons-material/Movie";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { amber, orange } from "@mui/material/colors";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DoldImage from "../assets/images/dold.gif";
import ResponsiveAppBar from "../components/AppBar";
import CurtainWithProgress from "../components/CurtainWithProgress";
import MenuItem from "../components/MenuItem";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { api } from "../resources/config";
import { TAccessRights, TSignedInUserWithSetUser, TUser } from "../resources/types";

const projectSetupItems = [
  {
    title: "Projects",
    description: "Set up projects",
    path: "/project",
    icon: AccountTreeIcon,
    viewable: (accessRights?: TAccessRights) =>
      accessRights?.canManageAllProjects,
  },
];

const userSetupItems = [
  {
    title: "Users",
    description: "View/edit users",
    path: "/user",
    icon: GroupsIcon,
    viewable: (accessRights?: TAccessRights) =>
      accessRights?.canManageProjectUsers,
  },
];

const items = [
  {
    title: "Media",
    description: "Manage media items",
    path: "/media",
    icon: MovieIcon,
    viewable: (accessRights?: TAccessRights) => accessRights?.canViewMedia,
  },
  {
    title: "Task Units",
    description: "Unit of task in an experiment",
    path: "/taskunit",
    icon: InboxIcon,
    viewable: (accessRights?: TAccessRights) => accessRights?.canViewTaskUnits,
  },
  {
    title: "Experiments",
    description: "Compose an experiment",
    path: "/experiment",
    icon: AllInboxIcon,
    viewable: (accessRights?: TAccessRights) =>
      accessRights?.canViewExperiments,
  },
];

const dashboardItems = [
  {
    title: "Dashboard",
    description: "Response data at a glance",
    path: "/dashboard",
    icon: DashboardIcon,
    viewable: (accessRights?: TAccessRights) =>
      accessRights?.canViewResponseData,
  },
];

function MainMenuPage() {
  const [activeMenuItem, setActiveMenuItem] = React.useState<string | null>(
    null
  );
  const [defaultProjectIdSpecified, setDefaultProjectIdSpecified] =
    React.useState(false);
  const navigate = useNavigate();
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [userData, setUserData] = React.useState<TUser | null>(null);
  // const [selectedProject, setSelectedProject] = React.useState('')

  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    ["user-data", signedInUserWithSetUser?.user?.username],
    () =>
      fetch(api.getUser(signedInUserWithSetUser?.user?.username), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username,
    }
  );

  React.useEffect(() => {
    if (data?.docs?.[0]) {
      setUserData(data?.docs?.[0] as TUser);
    }
  }, [data]);

  React.useEffect(() => {
    if (isError) {
      console.log(`isError: ${error}`);
      if ((error as Error).message === "Unauthorized") {
        signedInUserWithSetUser?.setUser(null);
      }
    }
  }, [isError, error, signedInUserWithSetUser]);

  React.useEffect(() => {
    if (userData?.projects && !defaultProjectIdSpecified) {
      signedInUserWithSetUser?.setProjects &&
        signedInUserWithSetUser?.setProjects(userData?.projects);
      const defaultProjectId = signedInUserWithSetUser?.projectCodeInUse;
      defaultProjectId &&
        signedInUserWithSetUser?.setProjectCodeInUse &&
        signedInUserWithSetUser?.setProjectCodeInUse(defaultProjectId);
      setDefaultProjectIdSpecified(true);
    }
  }, [defaultProjectIdSpecified, signedInUserWithSetUser, userData]);

  const handleClickItem = (path: string) => {
    navigate(path);
  };

  const handleLearnMoreButton = () => {
    navigate("/learn-more");
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleMouseEnter = (menuItemTitle: string) => {
    setActiveMenuItem(menuItemTitle);
  };

  const handleMouseLeave = () => {
    setActiveMenuItem(null);
  };

  const inProgress = React.useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  // const accessRight = React.useMemo(
  //   () => {
  //     if (signedInUserWithSetUser?.user?.role) {
  //       return accessRights[signedInUserWithSetUser?.user?.role as keyof {
  //         [role: string]: TAccessRights
  //       }]
  //     }
  //   },
  //   [signedInUserWithSetUser?.user?.role]
  // )

  // const handleProjectChange = (code: string) => {
  //   console.log(`handleProjectChange, code = ${code}`)
  //   // setSelectedProject(code)
  //   signedInUserWithSetUser?.setProjectCodeInUse && signedInUserWithSetUser?.setProjectCodeInUse(code)
  // }

  return (
    <Paper elevation={0}>
      <ResponsiveAppBar />
      {!signedInUserWithSetUser?.user?.username && (
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ p: 4, minHeight: "80vh" }}
        >
          <img src={DoldImage} alt="DOLD" style={{ width: "60vw" }} />
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: orange[600],
              fontSize: "2vmin",
              p: 2,
              pr: 3,
              pl: 3,
            }}
            endIcon={<SendIcon />}
            onClick={handleLearnMoreButton}
          >
            Learn more about DOLD
          </Button>
          <Box sx={{ flexGrow: 0 }}>
            <Stack flexDirection="row" alignItems="center" sx={{mt: 3}}>
              <Button
                sx={{
                  backgroundColor: amber[200],
                  color: '#666',
                  fontSize: "1.2vmin",
                  mr: 2,
                  pl: 2,
                  pr: 2,
                }}
                onClick={handleSignIn}
              >
                Sign in
              </Button>
              <Button
                sx={{
                  backgroundColor: amber[200],
                  color: '#666',
                  fontSize: "1.2vmin",
                  ml: 2,
                  pl: 2,
                  pr: 2,
                  // pl: 3,
                }}
                size="small"
                onClick={handleSignUp}
              >
                Sign up
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
      {signedInUserWithSetUser?.user?.username && (
        <Stack sx={{ m: 4 }}>
          {userData && (
            <Box>
              <ProjectInUseSelector readOnly={false} />
            </Box>
          )}

          {signedInUserWithSetUser?.accessRight?.canManageAllProjects && (
            <React.Fragment>
              <Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
                Project Management
              </Typography>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"flex-start"}
                flexWrap={"wrap"}
                mt={2}
                mb={4}
              >
                {projectSetupItems.map((item) => (
                  <MenuItem
                    key={item.title}
                    title={item.title}
                    Icon={item.icon}
                    description={item.description}
                    onClick={() => handleClickItem(item.path)}
                    onMouseEnter={() => handleMouseEnter(item.title)}
                    onMouseLeave={handleMouseLeave}
                    active={activeMenuItem === item.title}
                  />
                ))}
              </Box>
            </React.Fragment>
          )}

          {signedInUserWithSetUser?.accessRight?.canManageProjectUsers && (
            <React.Fragment>
              <Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
                User Management
              </Typography>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"flex-start"}
                flexWrap={"wrap"}
                mt={2}
                mb={4}
              >
                {userSetupItems.map(
                  (item) =>
                    item.viewable(signedInUserWithSetUser?.accessRight) && (
                      <MenuItem
                        key={item.title}
                        title={item.title}
                        Icon={item.icon}
                        description={item.description}
                        onClick={() => handleClickItem(item.path)}
                        onMouseEnter={() => handleMouseEnter(item.title)}
                        onMouseLeave={handleMouseLeave}
                        active={activeMenuItem === item.title}
                      />
                    )
                )}
              </Box>
            </React.Fragment>
          )}

          <Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
            Experiment Setup
          </Typography>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            mt={2}
            mb={4}
          >
            {items.map(
              (item) =>
                item.viewable(signedInUserWithSetUser?.accessRight) && (
                  <MenuItem
                    key={item.title}
                    title={item.title}
                    Icon={item.icon}
                    description={item.description}
                    onClick={() => handleClickItem(item.path)}
                    onMouseEnter={() => handleMouseEnter(item.title)}
                    onMouseLeave={handleMouseLeave}
                    active={activeMenuItem === item.title}
                  />
                )
            )}
          </Box>
          <Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
            Response Data
          </Typography>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            mt={2}
            mb={4}
          >
            {dashboardItems.map(
              (item) =>
                item.viewable(signedInUserWithSetUser?.accessRight) && (
                  <MenuItem
                    key={item.title}
                    title={item.title}
                    Icon={item.icon}
                    description={item.description}
                    onClick={() => handleClickItem(item.path)}
                    onMouseEnter={() => handleMouseEnter(item.title)}
                    onMouseLeave={handleMouseLeave}
                    active={activeMenuItem === item.title}
                  />
                )
            )}
          </Box>
        </Stack>
      )}
      {inProgress && <CurtainWithProgress open={inProgress} />}
    </Paper>
  );
}

export default MainMenuPage;
