import React from 'react';
import { Box, TextField, Autocomplete, Divider, FormHelperText } from '@mui/material'
import languageCodes from '../resources/languageCodes.json'
import { getFlag } from '../libraries/helper';

type TLanguage = {
  "code": string;
  "label": string;
  "priority": boolean;
  "mandatory": boolean;
  "id": string;
  "lastPriority"?: boolean;
}

const LanguageSelector = ({ disabled = false, label, language, allowNil = false, required = false, helperText, onChange }: {
  disabled?: boolean;
  label?: string;
  language: string;
  allowNil?: boolean;
  required?: boolean;
  helperText?: string;
  onChange: (value: string) => void
}) => {
  const [languages, setLanguages] = React.useState<TLanguage[]>([])

  React.useEffect(
    () => {
      setLanguages(languageCodes)
    },
    []
  )


  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: TLanguage | null) => {
    console.log(value)
    onChange && value?.id && onChange(value.id)
  }

  const filterSortLanguages = (list: TLanguage[], priority = true, allowNil = false) => {
    const filtered = list.filter(v => v.mandatory || allowNil).filter(v => v.priority === priority)
    const sorted = filtered.sort((a: TLanguage, b: TLanguage) => a.mandatory ? a.label.localeCompare(b.label) : 1)
    return sorted
  }

  const filteredSortedLanguages = React.useMemo(
    () => {
      const priorityItems = filterSortLanguages(languages, true, allowNil)
      if (priorityItems.length > 0) {
        priorityItems[priorityItems.length - 1].lastPriority = true
      }
      const filtered: TLanguage[] = [
        ...priorityItems,
        ...filterSortLanguages(languages, false),
      ]

      return filtered
    },
    [languages, allowNil]
  )

  return (
    <Box display="flex" flexDirection={'column'} sx={{ padding: 2, /* borderColor: 'rgba(0, 0, 0, 0.87)', */borderColor: 'orange', borderWidth: 1, borderRadius: 1, borderStyle: 'solid' }}>
      <Box>
        <Autocomplete
          options={filteredSortedLanguages}
          getOptionLabel={(option: TLanguage) => `${option.label}`}
          value={languages.find(v => v.id === language) ?? null}
          isOptionEqualToValue={(option, value) => option === value}
          filterSelectedOptions
          renderOption={(props, option) => {
            const flag = getFlag(option.code)
            return (
              <React.Fragment>
                <li {...({...props, key: option.id})}>
                  {flag ? `${flag} ${option.label}` : option.label}
                </li>
                {option.lastPriority && <Divider />}
              </React.Fragment>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Language"
              placeholder="Click or type to search"
            />
          )}
          onChange={handleChange}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </Box >
  )
  /*
    return (
  
      <FormControl variant="outlined" required={required}>
        <InputLabel id="language-selector-label">{label ?? ''}</InputLabel>
        <Select
          disabled={disabled}
          labelId="language-selector-label"
          value={language}
          onChange={handleChange}
          label={label ?? ''}
        >
          {languageCodes.filter(v => !!v.priority).filter(v => v.mandatory || allowNil).map(v => <MenuItem key={v.id} value={v.id} > {v.label} </MenuItem>)}
          <Divider/>
          {languageCodes.filter(v => !v.priority).filter(v => v.mandatory || allowNil).map(v => <MenuItem key={v.id} value={v.id} > {v.label} </MenuItem>)}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>)
      */
}

export default LanguageSelector