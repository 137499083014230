import { Stack } from "@mui/material";
import EdUHKLogo from "../assets/images/EdUHK_Signature_RGB.png";
import CrllsLogo from "../assets/images/crlls-logo.png";
import DoldImage from "../assets/images/dold.gif";
import LmlLogo from "../assets/images/lml-logo.png";

import {
  Box,
  Typography
} from "@mui/material";

function MaintenancePage() {

  return (
    <Box
      className="App"
      sx={{
        display: "flex",
        flexDirection: { xs: "column" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ mb: 8 }}
        justifyContent="space-around"
      >
        <Box sx={{ m: 2 }}>
          <img src={EdUHKLogo} alt="EdUHK" height={120} />
        </Box>
        <Box sx={{ m: 2 }}>
          <img src={CrllsLogo} alt="CRLLS" height={64} />
        </Box>
        <Box sx={{ m: 2 }}>
          <img src={LmlLogo} alt="LML" height={64} />
        </Box>
      </Stack>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <img src={DoldImage} alt="DOLD" width={"60%"} />
        <Typography variant="h1">Under maintenance</Typography>
        <Typography variant="h6">Until HKT 6pm 15 Dec 2023</Typography>
      </Box>
    </Box>
  );
}

export default MaintenancePage;
