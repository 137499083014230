import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useTable } from "react-table";
import ResponsiveAppBar from "../components/AppBar";
import CurtainWithProgress from "../components/CurtainWithProgress";
import ErrorDisplay from "../components/ErrorDisplay";
import ItemTitleInEdit from "../components/ItemTitleInEdit";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import UserEditor from "../components/UserEditor";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { api, roles } from "../resources/config";
import { TSignedInUserWithSetUser, TUser, TUserProject } from "../resources/types";
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";
import usePageSize from "../hooks/usePageSize";
import PaginationControl from "../components/PaginationControl";

function UserPage() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [userForEdit, setUserForEdit] = React.useState<TUser | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [createNew, setCreateNew] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { pageSize, setPageSize } = usePageSize();
  const [sortColumn, setSortColumn] = React.useState("");
  const [isSortedDesc, setIsSortedDesc] = React.useState<boolean | undefined>(
    false
  );
  const [userDataFilter, setUserDataFilter] = React.useState("");
  const [search, setSearch] = React.useState("");
  const searchTextField = React.useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();
  const confirm = useConfirmation();

  const projectId = React.useMemo(
    () =>
      signedInUserWithSetUser?.projects?.find(
        (v) => v.code === signedInUserWithSetUser?.projectCodeInUse
      )?._id,
    [
      signedInUserWithSetUser?.projectCodeInUse,
      signedInUserWithSetUser?.projects,
    ]
  );

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: userData,
    refetch: refetchUserData,
  } = useQuery(
    [
      "user-data-by-project",
      signedInUserWithSetUser?.user?.username,
      projectId,
      currentPage,
      pageSize,
      sortColumn,
      isSortedDesc,
      search,
    ],
    () =>
      fetch(
        signedInUserWithSetUser?.accessRight?.canManageAllProjects
          ? api.getUsers({
            page: currentPage.toString(),
            limit: pageSize.toString(),
            sortColumn: sortColumn || "",
            isSortedDesc: isSortedDesc?.toString() || "false",
            search,
          })
          : api.getUsersByProject({
            projectId,
            page: currentPage.toString(),
            limit: pageSize.toString(),
            sortColumn: sortColumn || "",
            isSortedDesc: isSortedDesc?.toString() || "false",
            search,
          }),
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        }
      ).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: signedInUserWithSetUser?.accessRight?.canManageAllProjects || (!!signedInUserWithSetUser?.user?.username && !!projectId),
    }
  );

  React.useEffect(() => {
    if (isError) {
      console.log(`isError: ${error}`);
      if ((error as Error).message === "Unauthorized") {
        signedInUserWithSetUser?.setUser(null);
      }
    }
  }, [isError, error, signedInUserWithSetUser]);

  React.useEffect(() => {
    setTimeout(() => searchTextField.current?.focus(), 100);
  }, [userData]);

  const handleEdit = React.useCallback(
    (username: string) => {
      console.log(`handleEdit(): username = ${username}`);
      const user = userData?.docs?.find((v: TUser) => v.username === username);
      if (user) {
        setCreateNew(false);
        setUserForEdit({ ...user });
      }
    },
    [userData?.docs]
  );

  /*
  const handleDelete = React.useCallback(
    (username: string) => {
      console.log(`handleDelete(): username = ${username}`)
      const user = userData?.docs?.find((v: TUser) => v.username === username)
      if (user) {
        alert(`To delete ${username}`)
        // setCreateNew(false)
        // setUserForEdit({ ...user })
        handleUserDelete(username)
      }
    }, [userData?.docs]
  )
  */

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Username",
        accessor: "username",
        width: "10%",
        // Cell: ({ row, value }: { row: any, value: any }) => {
        //   const type = row.original.mimeTypeMajor
        //   return <Stack direction="row" spacing={2} justifyContent={'space-between'}>{value}<TypeIcon type={type} /></Stack>
        // }
      },
      {
        Header: "Name",
        accessor: "name",
        width: "10%",
      },
      {
        Header: "Note",
        accessor: "note",
        width: "15%",
      },
      {
        Header: "Projects",
        accessor: "projects",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return value?.map((v: TUserProject) => v.code)?.join(", ") ?? "";
        },
        width: "10%",
      },
      {
        Header: "Level of access",
        accessor: "role",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return roles.find((r) => r.id === value)?.label ?? "";
        },
        width: "10%",
      },
      {
        Header: "Created",
        accessor: "createdDate",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        width: "15%",
      },
      {
        Header: "Modified",
        accessor: "modifiedDate",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        width: "15%",
      },
      {
        Header: "",
        id: "edit",
        width: "5%",
        Cell: ({ row, value }: { row: any; value: string }) => {
          const { username, role } = row.original;
          // const disabled = !signedInUserWithSetUser?.accessRight?.managedRoles?.includes(role) && username !== signedInUserWithSetUser?.user?.username
          const disabled =
            !signedInUserWithSetUser?.accessRight?.managedRoles?.includes(role);
          return username ? (
            <IconButton
              disabled={disabled}
              onClick={() => handleEdit(username)}
            >
              <EditIcon />
            </IconButton>
          ) : null;
        },
      },
      {
        Header: "",
        id: "delete",
        width: "5%",
        Cell: ({ row, value }: { row: any; value: string }) => {
          const { username, role } = row.original;
          // const disabled = !signedInUserWithSetUser?.accessRight?.managedRoles?.includes(role) && username !== signedInUserWithSetUser?.user?.username
          const disabled =
            !signedInUserWithSetUser?.accessRight?.managedRoles?.includes(role);
          return username ? (
            <IconButton
              disabled={disabled}
              onClick={() => handleUserDelete(username)}
            >
              <DeleteIcon />
            </IconButton>
          ) : null;
        },
      },
    ];
  }, [handleEdit, signedInUserWithSetUser?.accessRight?.managedRoles]);

  const handleSort = (newSortColumn: string, newIsSortedDesc?: boolean) => {
    console.log(
      `in handleSort(),newSortColumn=${newSortColumn}, newIsSortedDesc=${newIsSortedDesc} `
    );
    setSortColumn(newSortColumn);
    setIsSortedDesc(newIsSortedDesc);
  };

  const handleAddNewUser = () => {
    console.log(`handleAddNewUser`);
    setCreateNew(true);
    setUserForEdit({
      username: "",
      name: "",
      password: "",
      role: "",
      projects: [],
      note: "",
      createdDate: new Date(),
      modifiedDate: new Date(),
    });
  };

  const handleUserChange = (user: TUser) => {
    // console.log(`handleUserChange(), user = ${JSON.stringify(user)}`)
    setUserForEdit(user);
  };

  const handleUserDelete = (username: string) => {
    // if (!userForEdit?.username) {
    //   return
    // }
    // const { username } = userForEdit
    // console.log(`handleUserDelete() username=${username}`)
    confirm({
      variant: "danger",
      title: `Delete user "${username}"`,
      description: `Are you sure to delete user "${username}"?`,
      cancelButtonText: "No",
      agreeButtonText: "Yes, go ahead to delete",
      catchOnCancel: true,
    })
      .then(() => {
        console.log(`Go ahead to delete ${username}`);
        setIsProcessing(true);
        fetch(api.deleteUser({ username }), {
          method: "DELETE",
          credentials: "include",
          headers: {
            // "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `User "${username}" is deleted successfully`,
            });
            setUserForEdit(null);
            refetchUserData();
          })
          .catch((error) => {
            setIsProcessing(false);
            snackbar({
              severity: "error",
              message: `Deletion of user "${username}" failed: ${(
                error as Error
              )?.toString()}`,
            });
          });
      })
      .catch(() => {
        setIsProcessing(false);
        snackbar({
          severity: "info",
          message: `Deletion of user "${username}" cancelled`,
        });
      });
  };

  const handleUserSave = async () => {
    console.log(`handleUserSave`);
    if (!userForEdit) {
      return;
    }
    setIsProcessing(true);
    fetch(createNew ? api.addUser() : api.updateUser(), {
      method: createNew ? "PUT" : "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
      },
      body: JSON.stringify(userForEdit),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (!createNew) {
          if (!json || json.username !== userForEdit.username) {
            throw new Error(
              `Failed to save user "${userForEdit.username}" ${json.message ?? ""
              }`
            );
          } else {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Saving user "${userForEdit.username}" successfully`,
            });
            setUserForEdit(null);
          }
        } else {
          if (json.code === "11000") {
            throw new Error(
              `Username "${userForEdit.username}" exists in the system. Please choose a different one.`
            );
          } else if (!json || json.username !== userForEdit.username) {
            throw new Error(
              `Failed to add user "${userForEdit.username}" ${json.message ?? ""
              }`
            );
          } else {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Creating new user "${userForEdit.username}" successfully`,
            });
            setUserForEdit(null);
          }
        }
        refetchUserData();
      })
      .catch((err) => {
        setIsProcessing(false);
        confirm({
          variant: "error",
          title: `Error saving user "${userForEdit.username}"`,
          description:
            err?.toString() ?? "Error saving user. Please try again later.",
          agreeButtonText: "OK",
        });
      });
  };

  const handleuserDataFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserDataFilter(event.target.value);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleuserDataFilterClear = () => {
    setUserDataFilter("");
    setCurrentPage(1);
    setSearch("");
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(userDataFilter);
  };

  const filteredProjectData = React.useMemo(
    () => {
      // const mediaDataFilterKeywords = userDataFilter.trim().split(/\s+/)
      // const filteredProjectData = userData?.docs.filter((v: TUser) => !userDataFilter ||
      //   matchKeywords([v.code, v.note, v.mimeTypeMajor], mediaDataFilterKeywords))
      // return {
      //   ...userData,
      //   docs: filteredProjectData
      // }
      return {
        ...userData,
      };
    },
    // [userData, userDataFilter]
    [userData]
  );

  const disabled = React.useMemo(
    () => isLoading || isFetching || isProcessing,
    [isLoading, isFetching, isProcessing]
  );

  return (
    <Paper elevation={0} sx={{ height: "100vh", overflow: "scroll" }}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "user"]} />
      <Stack sx={{ mt: 2 }}>
        {!signedInUserWithSetUser?.accessRight?.canManageAllProjects && (
          <Box sx={{ m: 1, ml: 4, mr: 4 }}>
            <ProjectInUseSelector readOnly={!!userForEdit} />
          </Box>
        )}
        <Box sx={{ mt: 2, ml: 4 }}>
          {userForEdit ? (
            <ItemTitleInEdit
              prefix={createNew ? `Add new user` : `Edit user`}
              itemTitle={userForEdit.username}
            />
          ) : (
            <Typography variant="h3">Users</Typography>
          )}
        </Box>
        {isError && (
          <Box sx={{ mt: 4, ml: 4, mr: 4, p: 4 }}>
            <ErrorDisplay error={(error as Error).toString()} />
          </Box>
        )}
        {!isError && !userForEdit && (
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
            sx={{ ml: 4 }}
          >
            <Button startIcon={<AddIcon />} onClick={handleAddNewUser}>
              Add a new user
            </Button>
          </Box>
        )}

        {/*userData && !userForEdit && <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
          <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="body1">Browse the users, or search with keywords.</Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="none"
            size="small"
            inputRef={searchTextField}
            value={userDataFilter}
            onChange={handleuserDataFilterChange}
            onKeyPress={handlePressEnter}
            placeholder={`e.g. "admin user"`}
            helperText={'Input some keywords to begin searching.'}
            InputProps={{
              endAdornment: [
                <InputAdornment position="end" key='search'><IconButton onClick={handleSearch}><SearchIcon /></IconButton></InputAdornment>,
                <InputAdornment position="end" key='clear'><IconButton onClick={handleuserDataFilterClear}><ClearIcon /></IconButton></InputAdornment>
              ]
            }}
          />
          </Box>*/}

        {!isError && userData && !userForEdit && userData.totalDocs === 0 && (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Typography variant="body1">No projects found</Typography>
          </Box>
        )}

        {!isError && userData && !userForEdit && userData.totalDocs > 0 && (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="caption" sx={{ mr: 4 }}>
                  {`Total: ${userData.totalDocs} records.` +
                    ` Record #${(userData.page - 1) * userData.limit + 1} to #${(userData.page - 1) * userData.limit +
                    userData.docs.length
                    }`}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="flex-end">
                <PaginationControl pageSize={pageSize} onPageSizeChange={setPageSize} sx={{ mr: 8 }} />
                <Button
                  startIcon={<ArrowBackIcon />}
                  disabled={!userData.hasPrevPage}
                  onClick={() => setCurrentPage(userData.prevPage)}
                >
                  Previous
                </Button>
                <Button
                  startIcon={<ArrowForwardIcon />}
                  disabled={!userData.hasNextPage}
                  onClick={() => setCurrentPage(userData.nextPage)}
                >
                  Next
                </Button>
              </Box>
            </Box>
            <UserDataTable
              columns={columns}
              data={filteredProjectData.docs}
              sortColumn={sortColumn}
              isSortedDesc={isSortedDesc}
              onSort={handleSort}
            />
          </Box>
        )}
        {!isError && userData && userForEdit && (
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-between"}
            sx={{ mr: 24 }}
          >
            <Box sx={{ ml: 4 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={4}
              >
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setUserForEdit(null)}
                >
                  Back to user list
                </Button>
              </Box>
              <UserEditor
                disabled={isProcessing}
                createNew={createNew}
                user={userForEdit}
                onSave={handleUserSave}
                onChange={handleUserChange}
              />
            </Box>
          </Box>
        )}
      </Stack>
      {disabled && !error && <CurtainWithProgress open={disabled} />}
    </Paper>
  );
}

const UserDataTable = ({
  columns,
  data,
  onSort,
  sortColumn,
  isSortedDesc,
}: {
  columns: any;
  data: any;
  onSort: (sortColumn: string, isSortedDesc?: boolean) => void;
  sortColumn: string;
  isSortedDesc: boolean | undefined;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableCell
                {...column.getHeaderProps()}
                onClick={(event) => {
                  if (column.id === sortColumn && isSortedDesc) {
                    onSort("");
                  } else if (
                    column.id === sortColumn &&
                    isSortedDesc === false
                  ) {
                    onSort(column.id, true);
                  } else {
                    onSort(column.id, false);
                  }
                }}
                sx={{ width: column.width }}
              >
                {column.render("Header")}
                <span>
                  {column.id === sortColumn
                    ? isSortedDesc
                      ? " 🔽"
                      : " 🔼"
                    : ""}
                </span>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <TableCell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default UserPage;
