import {
  Box,
  Paper,
  Typography
} from "@mui/material";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ResponsiveAppBar from "../components/AppBar";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import { doldDocLinks } from "../resources/config";

function DocumentationPage() {
  return (
    <Paper elevation={0}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "documentation"]} />
      <Box sx={{ ml: 4, mr: "25%", minWidth: 600, pb: 16 }}>
        <Box sx={{ pb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <a href={doldDocLinks.download} download="dold_doc.pdf" style={{ textDecoration: 'none' }}>Download<PictureAsPdfIcon sx={{ pl: 1, verticalAlign: 'middle' }} /></a> or <a href={doldDocLinks.view} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>open in another window<OpenInNewIcon sx={{ pl: 1, verticalAlign: 'middle' }} /></a>
          </Typography>
        </Box>
        <iframe src={doldDocLinks.preview} title="DOLD Documentation" width={1280} height={640} />
      </Box>
    </Paper>
  );
}

export default DocumentationPage;
