import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import { TSkipFlowControl } from "../resources/types";
import SkipFlowControlDescription from "./SkipFlowControlDescription";
import SkipFlowControlDialog from "./SkipFlowControlDialog";
import SkipFlowControlIcon from "./SkipFlowControlIcon";

const SkipFlowControl = ({
  skipFlowControl,
  onChange,
}: {
  skipFlowControl?: TSkipFlowControl;
  onChange: (s: TSkipFlowControl) => void;
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { enabled, lastNthElement, equalityCheck, targetValue } =
    skipFlowControl ?? {};

  // React.useEffect(
  //   () => {
  //     console.debug(`in SkipFlowControl, skipFlowControl=${JSON.stringify(skipFlowControl)}`)
  //   }, [skipFlowControl]
  // )

  const handleToggleSkipFlowControl = () => {
    console.debug(
      `SkipFlowControl: handleToggleSkipFlowControl, current enabled = ${enabled}`
    );
    // onChange({
    //   ...skipFlowControl,
    //   enabled: !enabled,
    // });
    setOpenDialog(true);
  };

  const handleCancel = () => {
    console.debug(`SkipFlowControl, handleCancel()`);
    setOpenDialog(false);
  };

  const handleConfirm = (s: TSkipFlowControl) => {
    onChange(s);
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderColor: red[300],
          borderWidth: 1,
          borderRadius: 1,
          borderStyle: "solid",
          px: 0.5,
          cursor: "pointer",
        }}
        onClick={handleToggleSkipFlowControl}
      >
        <SkipFlowControlIcon enabled={!!enabled} />
        {enabled && (
          <SkipFlowControlDescription
            lastNthElement={lastNthElement}
            equalityCheck={equalityCheck}
            targetValue={targetValue}
            sx={{
              pl: 1,
            }}
          />
        )}
        {!enabled && (
          <Typography sx={{ ml: 1, mr: 1, fontSize: "80%" }}>
            No skip
          </Typography>
        )}
      </Box>
      {openDialog && (
        <SkipFlowControlDialog
          show={openDialog}
          enabled={enabled}
          lastNthElement={lastNthElement}
          equalityCheck={equalityCheck}
          targetValue={targetValue}
          onClose={handleCancel}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </React.Fragment>
  );
};

export default SkipFlowControl;
