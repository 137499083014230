import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { downloadFile } from "../libraries/helper";
import { TExperiment } from "../resources/types";

const getLength = (x = 0) => x.toString().length

const ExperimentUrlGenerator = ({
  disabled = false,
  taskCode,
  taskUrl,
  onChange,
}: {
  disabled?: boolean;
  taskCode: string;
  taskUrl: string;
  onChange: (e: TExperiment) => void;
}) => {
  // const [taskCodeSelected, setTaskCodeSelected] = React.useState(true)
  const [teamCodeSelected, setTeamCodeSelected] = React.useState(true)
  const [teamCode, setTeamCode] = React.useState('team')
  const [teamCodeFrom, setTeamCodeFrom] = React.useState(1)
  const [teamCodeTo, setTeamCodeTo] = React.useState(10)
  const [userCodeSelected, setUserCodeSelected] = React.useState(true)
  const [userCode, setUserCode] = React.useState('user')
  const [userCodeFrom, setUserCodeFrom] = React.useState(1)
  const [userCodeTo, setUserCodeTo] = React.useState(10)

  // const handleToggleTaskCodeSelected = () => {
  //   setTaskCodeSelected(selected => !selected)
  // }
  const handleToggleTeamCodeSelected = () => {
    setTeamCodeSelected(selected => !selected)
  }
  const handleChangeTeamCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTeamCode(event.target.value)
  }
  const handleChangeTeamCodeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    setTeamCodeFrom(value < 1 ? 1 : value)
  }
  const handleChangeTeamCodeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    setTeamCodeTo(value < 1 ? 1 : value)
  }
  const handleToggleUserCodeSelected = () => {
    setUserCodeSelected(selected => !selected)
  }
  const handleChangeUserCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCode(event.target.value)
  }
  const handleChangeUserCodeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    setUserCodeFrom(value < 1 ? 1 : value)
  }
  const handleChangeUserCodeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    setUserCodeTo(value < 1 ? 1 : value)
  }
  const handleDownloadUrlListClick = () => {
    const userIdList = [];
    const teamCodeDigitCount = teamCodeSelected ? Math.max(getLength(teamCodeFrom), getLength(teamCodeTo)) : 0
    const userCodeDigitCount = userCodeSelected ? Math.max(getLength(userCodeFrom), getLength(userCodeTo)) : 0

    for (let t = teamCodeFrom; t <= (teamCodeSelected ? teamCodeTo : teamCodeFrom); t++) {
      for (let u = userCodeFrom; u <= userCodeTo; u++) {
        const team = teamCodeSelected ? `${teamCode}${String(t).padStart(teamCodeDigitCount, '0')}` : ''
        const user = `${userCode}${String(u).padStart(userCodeDigitCount, '0')}`
        const id = team ? `${team}_${user}` : user
        userIdList.push(id)
      }
    }
    const csvData = userIdList.map(v => `${taskUrl}&id=${v}`).join("\n")
    downloadFile(csvData)
  }
  const preview = React.useMemo(
    () => {
      const userIdPreviewList = [];
      const teamCodeDigitCount = teamCodeSelected ? Math.max(getLength(teamCodeFrom), getLength(teamCodeTo)) : 0
      const userCodeDigitCount = userCodeSelected ? Math.max(getLength(userCodeFrom), getLength(userCodeTo)) : 0

      let teamPreviewCount = 2;

      for (let t = teamCodeFrom; t <= (teamCodeSelected ? teamCodeTo : teamCodeFrom); t++) {
        let userPreviewCount = 2;
        teamPreviewCount = teamPreviewCount - 1;
        if (teamPreviewCount < 0) {
          userIdPreviewList.push(":")
          break;
        }
        for (let u = userCodeFrom; u <= userCodeTo; u++) {
          userPreviewCount = userPreviewCount - 1;
          if (userPreviewCount < 0) {
            userIdPreviewList.push(":")
            break;
          }
          const team = teamCodeSelected ? `${teamCode}${String(t).padStart(teamCodeDigitCount, '0')}` : ''
          const user = `${userCode}${String(u).padStart(userCodeDigitCount, '0')}`
          const id = team ? `${taskUrl}&id=${team}_${user}` : `${taskUrl}&id=${user}`
          userIdPreviewList.push(id)
        }
      }
      return userIdPreviewList
    },
    [taskUrl, teamCode, teamCodeFrom, teamCodeSelected, teamCodeTo, userCode, userCodeFrom, userCodeSelected, userCodeTo]
  )

  return (
    <Stack spacing={2} padding={2} sx={{ mb: 10 }} style={{
      borderWidth: 1,
      borderColor: 'lightgrey',
      borderStyle: 'solid',
      borderRadius: 8
    }}>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Generate a list of experiment URL's with varying ID
      </Typography>
      {/*<Stack direction="row" spacing={2} alignItems="center">
        <Checkbox
          checked={taskCodeSelected}
          onChange={handleToggleTaskCodeSelected}
        />
        <TextField
          disabled={!taskCodeSelected}
          fullWidth
          label={'Task code'}
          inputProps={{
            style: {
              color: blue[900]
            }
          }}
          InputProps={{ readOnly: true }}
          value={taskCode}
        />
        </Stack>*/}
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          variant="standard"
          fullWidth
          label={'Team code'}
          placeholder={'e.g. site, team, etc.'}
          InputProps={{
            startAdornment: <Checkbox
              size="small"
              checked={teamCodeSelected}
              onChange={handleToggleTeamCodeSelected}
            />
          }}
          inputProps={{
            style: {
              color: blue[900]
            }
          }}
          value={teamCode}
          onChange={handleChangeTeamCode}
        />
        <TextField
          variant="standard"
          disabled={!teamCodeSelected}
          fullWidth
          label={'From'}
          InputProps={{
            inputProps: {
              min: 1,
              style: {
                color: blue[900],
                textAlign: 'right',
              }
            }
          }}
          style={{ width: '24ch' }}
          type="number"
          value={teamCodeFrom}
          onChange={handleChangeTeamCodeFrom}
        />
        <TextField
          variant="standard"
          disabled={!teamCodeSelected}
          fullWidth
          label={'To'}
          inputProps={{
            min: 1,
            style: {
              color: blue[900],
              textAlign: 'right',
            }
          }}
          style={{ width: '24ch' }}
          type="number"
          value={teamCodeTo}
          onChange={handleChangeTeamCodeTo}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        {/*<Checkbox
          checked={userCodeSelected}
          disabled
          readOnly={true}
        />*/}
        <TextField
          variant="standard"
          disabled={!userCodeSelected}
          fullWidth
          label={'User code'}
          placeholder={'e.g. user, etc.'}
          inputProps={{
            style: {
              color: blue[900]
            }
          }}
          value={userCode}
          onChange={handleChangeUserCode}
        />
        <TextField
          variant="standard"
          disabled={!userCodeSelected}
          fullWidth
          label={'From'}
          InputProps={{
            inputProps: {
              min: 1,
              style: {
                color: blue[900],
                textAlign: 'right'
              }
            }
          }}
          style={{ width: '24ch' }}
          type="number"
          value={userCodeFrom}
          onChange={handleChangeUserCodeFrom}
        />
        <TextField
          variant="standard"
          disabled={!userCodeSelected}
          fullWidth
          label={'To'}
          inputProps={{
            min: 1,
            style: {
              color: blue[900],
              textAlign: 'right'
            }
          }}
          style={{ width: '24ch' }}
          type="number"
          value={userCodeTo}
          onChange={handleChangeUserCodeTo}
        />
      </Stack>
      <Box display="flex" flexDirection="column" alignItems="center">
        {preview.length > 0 && <Typography variant="body2" sx={{ fontWeight: 'bold', pb: 1, alignSelf: 'flex-start' }}>Preview</Typography>}
        {preview.map((p, index) => <Typography key={index} variant="body2">{p}</Typography>)}
      </Box>
      <Button
        endIcon={<DownloadIcon
        />}
        onClick={handleDownloadUrlListClick}
      >Download list</Button>
    </Stack>
  );
};

export default ExperimentUrlGenerator;
