import React from "react";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { TSignedInUserWithSetUser } from "../resources/types";

export default function usePageSize() {
    const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
        React.useContext(SignedInUserContext);
    const [pageSize, setPageSize] = React.useState(50)

    React.useEffect(
        () => {
            if (!!signedInUserWithSetUser?.pageSize && pageSize != signedInUserWithSetUser?.pageSize) {
                setPageSize(signedInUserWithSetUser?.pageSize)
                signedInUserWithSetUser?.setPageSize && signedInUserWithSetUser?.setPageSize(signedInUserWithSetUser?.pageSize)
            }
        },
        [signedInUserWithSetUser?.pageSize]
    )

    const updatePageSize = React.useCallback((newPageSize: number) => {
        setPageSize(newPageSize)
        signedInUserWithSetUser?.setPageSize && signedInUserWithSetUser?.setPageSize(newPageSize)
    }, [])

    return { pageSize, setPageSize: updatePageSize }
}