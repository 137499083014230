import KeyIcon from "@mui/icons-material/Key";
import LoginIcon from "@mui/icons-material/Login";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import DoldImage from "../assets/images/dold.gif";
import InstituteLogo from "../components/InstituteLogo";
import SiteLogo from "../components/SiteLogo";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { api } from "../resources/config";
import { TSignedInUserWithSetUser } from "../resources/types";
import { useConfirmation } from "../services/ConfirmationService";

function LoginPage() {
  const navigate = useNavigate();
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  // const userWithSetUser: UserWithSetUser | null = React.useContext(UserContext)
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [signingIn, setSigningIn] = React.useState(false);
  const confirm = useConfirmation();

  React.useEffect(() => {
    if (signedInUserWithSetUser?.user?.username) {
      navigate("/home");
    }
  }, [signedInUserWithSetUser, navigate]);

  const handleLogin = async () => {
    if (!username || !password) {
      return;
    }
    try {
      setSigningIn(true);
      const response = await fetch(api.login(), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      }).then((res) => res.json());
      console.log(response);
      if (response.error) {
        throw new Error(response.error);
      }
      const { role, token } = response;
      signedInUserWithSetUser?.setUser({
        username,
        role,
        token,
      });
      navigate("/home");
      // alert(`Login success, role = ${role}`)
    } catch (err) {
      setErrorText((err as Error).message ?? "Failed to sign in");
    } finally {
      setSigningIn(false);
    }
  };
  /*
    const handleLogin = async () => {
      if (!username || !password) {
        return
      }
      setSigningIn(true)
      const validUser = users.find(u => u.username === username)
      if (username === password && validUser) {
        console.log(`username = ${username}`)
        const newUser: User = {
          username: validUser.username,
          fullName: validUser.fullName,
          role: validUser.role
        }
        const filtered = menu.filter(v => v.items.some(item => item.roles.includes(validUser.role))).map(v => ({
          ...v,
          items: v.items.filter(item => item.roles.includes(validUser.role))
        }))
        const defaultPath = filtered[0]?.items[0].path
        setTimeout(
          () => {
            userWithSetUser?.setUser(newUser)
            setSigningIn(false)
            defaultPath && navigate(defaultPath)
          }, 2000
        )
      } else {
        setErrorText('Incorrect username or password')
        setSigningIn(false)
      }
    }
    */

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setErrorText("");
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setErrorText("");
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack flexDirection="column" spacing={2} alignItems="center">
        <Stack flexDirection="row">
          <InstituteLogo />
          <SiteLogo />
        </Stack>
        <img
          src={DoldImage}
          alt="Digital platform for collecting Online Language Data (DOLD)"
          style={{ width: "80vmin" }}
        />
        {signedInUserWithSetUser?.user && (
          <Box>Signed in as {signedInUserWithSetUser?.user?.username}</Box>
        )}
        {(!signedInUserWithSetUser || !signedInUserWithSetUser.user) && (
          <Stack alignItems="center" spacing={2} sx={{ width: "60vmin" }}>
            <TextField
              label="Username"
              disabled={signingIn}
              fullWidth
              value={username}
              onChange={handleUsernameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PermIdentityIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Password"
              disabled={signingIn}
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errorText && (
              <Typography variant="body2" sx={{ color: red[500] }}>
                {errorText}
              </Typography>
            )}
            <Button
              variant="outlined"
              disabled={!username || !password || signingIn}
              fullWidth
              color="primary"
              onClick={handleLogin}
              endIcon={
                signingIn ? <CircularProgress size={16} /> : <LoginIcon />
              }
              sx={{ height: "3rem" }}
            >
              Sign in
            </Button>
            <Typography
              color="secondary"
              variant="body2"
              sx={{ pt: 2, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Back to main page
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}

export default LoginPage;
